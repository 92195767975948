import React from "react";
import { Button } from "../ui/button";
import ArtistDropDown from "./ArtistDropDown";
import CaptionItem from "./CaptionItem";

interface ArtistProp {
  name: string;
  img: string;
  dob: string;
  className?: string; // Made optional
  boardId: string;
  coverId: string;
  itemId: string;
  tagList: string[];
  itemType: string;
  caption?: string;
  showDetail?: boolean; // Corrected type
  showEllipsis?: boolean; // Added new prop
  showCaption?: boolean;
}

const getYearFromDateString = (dateString: string): number => {
  const date = new Date(dateString);
  return date.getFullYear();
};

const Artist: React.FC<ArtistProp> = ({
  name,
  img,
  dob,
  className = "",
  boardId,
  itemId,
  itemType,
  caption,
  showDetail = true,
  showEllipsis = true,
  coverId,
  tagList,
  showCaption = true,
}) => {
  return (
    <div className="flex flex-col">
      <div className={`min-w-[297px] w-full h-[297px] relative ${className}`}>
        <img className="object-cover w-full h-full" src={img} alt={name} />

        <div className="absolute inset-0 rounded-t-lg bg-gradient-to-t from-zinc-900 to-transparent"></div>
        {showEllipsis && (
          <ArtistDropDown
            boardId={boardId}
            itemId={itemId}
            itemType={itemType}
            caption={caption}
            coverId={coverId}
            imgSrc={img}
            tagList={tagList}
          >
            <img
              className="absolute top-0 right-0 p-2 cursor-pointer"
              src="/ellipsis.svg"
              alt="Options"
            />
          </ArtistDropDown>
        )}
        {showDetail && (
          <div className="absolute bottom-0 left-0 p-4">
            <p className="text-5xl font-normal text-white font-eb">{name}</p>
            <p className="text-[#CCCCCC] font-sh5 font-normal text-xs">
              b. {getYearFromDateString(dob)}
            </p>
          </div>
        )}

        {showDetail && (
          <div className="absolute bottom-0 right-0 p-4">
            <Button
              size="icon"
              variant="outline"
              className="w-[104px] text-white rounded-full font-sh5 font-normal text-sm"
            >
              FOLLOW
            </Button>
          </div>
        )}
      </div>
      {showCaption && (caption ? <CaptionItem caption={caption} /> : null)}
    </div>
  );
};

export default Artist;
