import React, { ReactNode, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { DialogClose } from "@radix-ui/react-dialog";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer";
import useBoard from "@/apis/board";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { cn } from "@/lib/utils";
import { CreateBoardParams } from "@/hooks/useFilter";
import toast from "react-hot-toast";
import { useMediaQuery } from "@mui/material";
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { GetSingleBoardResponse } from "@/types/boards.type";

type NewBoardProps = {
  children: ReactNode;
  boardId: string;
  boardName: string;
  nameBoard: string;
  descBoard: string;
  icon: number;
};
const EditBoard: React.FC<NewBoardProps> = ({
  children,
  nameBoard,
  descBoard,
  boardId,
  boardName,
  icon,
}) => {
  const [open, setOpen] = useState(false);

  const isDesktop = useMediaQuery("(min-width: 768px)");

  if (isDesktop) {
    return (
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger className="text-lg text-white bg-transparent">
          {children}
        </DialogTrigger>
        <DialogContent className="max-w-[640px]">
          <DialogHeader className="px-8">
            <DialogTitle className="pb-5 border-[#E5E5E5] border-b-[1px] border-solid text-gray-100 font-normal font-sh5 text-[28px]">
              <div className="flex flex-row justify-between">
                <p>Edit Board</p>
                <div className="flex flex-row items-center gap-4">
                  <DialogClose asChild>
                    <img
                      className="cursor-pointer"
                      src="/close1.svg"
                      alt="Close"
                    />
                  </DialogClose>
                </div>
              </div>
            </DialogTitle>
          </DialogHeader>
          {/* Pass setOpen to ProfileForm */}
          <ProfileForm
            nameBoard={nameBoard}
            descBoard={descBoard}
            icon={icon}
            setOpen={setOpen}
          />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer open={open} onOpenChange={setOpen}>
      <DialogTrigger
        onClick={() => setOpen(true)}
        className="text-lg text-white bg-transparent"
      >
        {children}
      </DialogTrigger>
      <DrawerContent className="rounded-none h-4/5">
        <DrawerHeader className="p-5 text-left">
          <div className="flex flex-row items-center justify-between pb-2  border-b-[1px]  border-bdr-10 border-solid">
            <DrawerTitle className="text-5xl font-normal text-gray-100 font-sh5">
              Edit Board
            </DrawerTitle>
            <DialogClose asChild>
              <img className="cursor-pointer" src="/close1.svg" alt="Close" />
            </DialogClose>
          </div>
        </DrawerHeader>
        {/* Pass setOpen to ProfileForm */}
        <ProfileForm
          setOpen={setOpen}
          nameBoard={nameBoard}
          descBoard={descBoard}
          icon={icon}
        />
      </DrawerContent>
    </Drawer>
  );
};
interface ProfileFormProps {
  nameBoard: string;
  descBoard: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  icon: number;
}

function ProfileForm({
  nameBoard,
  descBoard,
  setOpen,
  className,
  icon,
}: React.ComponentProps<"form"> & ProfileFormProps) {
  const [name, setName] = useState<string>(nameBoard); // Controlled state
  const { boardId } = useParams<{ boardId: string }>();
  const [description, setDescription] = useState<string>(descBoard); // Controlled state
  const [selectedImageId, setSelectedImageId] = useState<number>(icon || 0);
  const countWords = (text: string) => {
    return text.trim().split(/\s+/).filter(Boolean).length;
  };
  const wordLimit = 140;
  const imageSources = [
    { id: 0, src: "/starLogoAlt.svg", alt: "Star Logo" },
    { id: 1, src: "/caseAlt.svg", alt: "Case Icon" },
    { id: 2, src: "/eyeAlt.svg", alt: "Eye Icon" },
    { id: 3, src: "/framingAlt.svg", alt: "Framing Icon" },
    { id: 4, src: "/paintAlt.svg", alt: "Paint Icon" },
    { id: 5, src: "/playAlt.svg", alt: "Play Icon" },
    { id: 6, src: "/scholarAlt.svg", alt: "Scholar Icon" },
    { id: 7, src: "/tvAlt.svg", alt: "TV Icon" },
    { id: 8, src: "/starAlt.svg", alt: "Star Alt Icon" },
    { id: 9, src: "/personAlt.svg", alt: "Person Icon" },
    { id: 10, src: "/writingAlt.svg", alt: "Writing Icon" },
    { id: 11, src: "/heart.svg", alt: "Heart Icon" },
  ];

  const { editSingleBoard } = useBoard();
  const queryClient = useQueryClient();
  const [editing, setEditing] = useState(false);
  const mutation = useMutation({
    mutationFn: editSingleBoard,
    onSuccess: () => {
      setEditing(false);
      setName(""); // Reset form fields
      setDescription("");
      setSelectedImageId(0);
      queryClient.invalidateQueries({ queryKey: ["single-board"] });
      setOpen(false);
      toast.success("Board updated successfully!", {
        id: "board-update",
      });
    },
    onError: () => {
      toast.error("Failed to update board. Please try again.", {
        id: "board-update",
      });
    },
    onMutate: () => {
      toast.loading("Editing the board...", {
        id: "board-update",
      });
    },
  });

  const handleEditBoard = () => {
    if (name.trim() === "") {
      toast.error("Enter all details");
      return;
    }

    const updatedBoard = {
      name,
      description,
      icon: selectedImageId,
    };

    mutation.mutate({ boardId: boardId as string, params: updatedBoard });
  };

  return (
    <form className={cn("grid items-start gap-4 overflow-auto", className)}>
      <div className="mt-[20px]">
        <div className="flex flex-col gap-[20px] px-4">
          <div className="flex flex-col gap-3">
            <div className="text-base font-normal text-gray-100 font-sh5 mq450:hidden">
              Select Board Icon
            </div>
            <div className="flex flex-row items-center gap-6 mq450:flex-col">
              <div className="flex items-center justify-center min-w-[120px] h-[120px] rounded-full border-[1px] border-solid border-bdr-10">
                <img
                  className="w-[72px] h-[72px] "
                  src={imageSources[selectedImageId].src}
                  alt={imageSources[selectedImageId].alt}
                />
              </div>
              <div>
                <div className="flex flex-wrap w-full gap-2 py-2 mq450:justify-between">
                  {imageSources.map((image) => (
                    <img
                      key={image.id}
                      className={`w-10 h-10 border-solid p-2 transition-transform duration-200 ease-in-out cursor-pointer border-[1px] ${
                        selectedImageId === image.id
                          ? "bg-[#F6F6F5] border-[#7AC51C]"
                          : "border-transparent"
                      } hover:border-[#7AC51C]`}
                      src={image.src}
                      alt={image.alt}
                      onClick={() => setSelectedImageId(image.id)}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <div className="text-base font-normal text-gray-100 mq450:text-left font-sh5">
              Name of the Board
            </div>
            <input
              value={name} // Use controlled input
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter Name"
              className="p-[20px] h-[50px] border-[1px] border-solid border-bdr-10"
            />
          </div>
          <div className="flex flex-col gap-3">
            <div className="text-base font-normal text-gray-100 mq450:text-left font-sh5">
              Description (Optional)
            </div>
            <input
              value={description} // Use controlled input
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter Description"
              className="p-[20px] h-[50px] font-sh5 border-[1px] border-solid border-bdr-10"
            />
            <div className="mt-2 text-left text-gray-500">
              {countWords(description)}/{wordLimit}
            </div>
          </div>
        </div>
        <div className="flex mt-2 flex-row justify-between w-full px-5 py-3 bg-[#F2F2F2]">
          <DialogClose asChild>
            <button
              className="relative text-sm font-bold text-gray-100 underline bg-transparent rounded-none "
              type="button"
              disabled={editing}
            >
              CANCEL
            </button>
          </DialogClose>
          <Button
            onClick={handleEditBoard}
            className="rounded-[50px] px-4 font-bold text-sm text-white"
            variant="default"
            type="button"
            disabled={editing}
          >
            {editing ? "SAVING..." : "SAVE"}
          </Button>
        </div>
      </div>
    </form>
  );
}

export default EditBoard;
