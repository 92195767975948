import React, { useState } from "react";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerDescription,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { useMediaQuery } from "@mui/material";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";

interface SortingOptionsDropdownProps {
  position: string;
  setPosition: (position: string) => void;
}

const SortingOptionsDropdown: React.FC<SortingOptionsDropdownProps> = ({
  position,
  setPosition,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 450px)");

  const handleOptionChange = (value: string) => {
    setPosition(value);
    setDrawerOpen(false); // Close the drawer after selecting an option
  };

  const optionLabels: { [key: string]: string } = {
    recently: "Recently added",
    newtoold: "New to Old",
    AtoZ: "A to Z",
    ZtoA: "Z to A",
  };

  return isDesktop ? (
    <DropdownMenu>
      <DropdownMenuTrigger className="px-5 py-3" asChild>
        <Button variant="outline" className="rounded-[50px] w-[157px] group">
          <div className="flex flex-row justify-between w-full">
            <p className="font-normal text-gray-60 text-sm text-left truncate group-hover:text-white">
              {optionLabels[position] || optionLabels.recently}
            </p>
            <img className="ml-2" src="/arrow.svg" alt="arrow" />
          </div>
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="w-[300px] p-6">
        <ProfileForm
          position={position}
          onOptionChange={handleOptionChange}
          isDesktop={isDesktop}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  ) : (
    <Drawer open={drawerOpen} onOpenChange={setDrawerOpen}>
      <DrawerTrigger asChild>
        <Button variant="outline" className="rounded-[50px]">
          {optionLabels[position] || optionLabels.recently}
          <img className="ml-5" src="/arrow.svg" alt="arrow" />
        </Button>
      </DrawerTrigger>
      <DrawerContent className="p-4 rounded-[0px]">
        <DrawerHeader>
          <DrawerDescription>
            <ProfileForm
              position={position}
              onOptionChange={handleOptionChange}
              isDesktop={isDesktop}
            />
          </DrawerDescription>
        </DrawerHeader>
      </DrawerContent>
    </Drawer>
  );
};

interface ProfileFormProps {
  position: string;
  onOptionChange: (value: string) => void;
  isDesktop: boolean;
}

const ProfileForm: React.FC<ProfileFormProps> = ({
  position,
  onOptionChange,
  isDesktop,
}) => {
  const options = [
    { value: "recently", label: "Recently added" },
    { value: "newtoold", label: "New to Old" },
    { value: "AtoZ", label: "A to Z" },
    { value: "ZtoA", label: "Z to A" },
  ];

  return (
    <RadioGroup
      className=" gap-[24px]"
      value={position}
      onValueChange={onOptionChange}
    >
      {options.map((option) => (
        <div className="flex items-center gap-4" key={option.value}>
          <RadioGroupItem value={option.value} id={`option-${option.value}`} />
          <Label
            className="text-base font-sh5 text-gray-60 font-normal"
            htmlFor={`option-${option.value}`}
          >
            {option.label}
          </Label>
        </div>
      ))}
    </RadioGroup>
  );
};

export default SortingOptionsDropdown;
