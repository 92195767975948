import React from "react";
import ArtistDropDown from "./ArtistDropDown";
import CaptionItem from "./CaptionItem";

interface FileProp {
  image: string;
  className?: string;
  fileName: string;
  itemId: string;
  boardId: string;
  itemType: string;
  coverId: string;
  caption: string;
  imgSrc: string;
  tagList: string[];
  showEllipsis?: boolean;
  showCaption?: boolean;
}

const FileType: React.FC<FileProp> = ({
  image,
  className,
  fileName,
  boardId,
  itemId,
  itemType,
  imgSrc,
  caption,
  showEllipsis = true,
  tagList,
  coverId,
  showCaption = true,
}) => {
  const onImageClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <>
      <div
        className={`relative h-[297px] min-w-[297px] mq450:min-w-[156px] mq450:h-[156px] ${className}`}
      >
        <img className="object-cover w-full h-full" src={image} alt="File" />

        <div className="absolute inset-0 rounded-t-lg bg-gradient-to-t from-zinc-900 to-transparent"></div>
        <div className="absolute inset-0 rounded-t-lg bg-gradient-to-t from-zinc-900 to-transparent"></div>

        <p className="absolute bottom-0 p-4 text-xs font-medium text-white font-sh5">
          {fileName}
        </p>
        {showEllipsis && (
          <ArtistDropDown
            boardId={boardId}
            itemId={itemId}
            itemType={itemType}
            caption={caption}
            coverId={coverId}
            imgSrc={imgSrc}
            tagList={tagList}
          >
            <img
              onClick={onImageClick}
              className="absolute top-0 right-0 p-2"
              src="/ellipsis.svg"
            />
          </ArtistDropDown>
        )}
      </div>
      {showCaption && (caption ? <CaptionItem caption={caption} /> : null)}
    </>
  );
};

export default FileType;
