import React, { useState, useRef } from "react";
import ArtistDropDown from "./ArtistDropDown";
import CaptionItem from "./CaptionItem";

interface VideoProp {
  videoUrl: string;
  coverphoto: string;
  title: string;
  className?: string;
  itemId: string;
  caption: string;
  boardId: string;
  coverId: string;
  tagList: string[];
  showCaption: boolean;
  showEllipsis: boolean;
}

const VideoType: React.FC<VideoProp> = ({
  videoUrl,
  coverphoto,
  title,
  coverId,
  className = "",
  itemId,
  caption,
  boardId,
  tagList,
  showCaption = true,
  showEllipsis = true,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handlePlay = () => {
    setIsPlaying(!isPlaying);
  };

  const handleVideoError = (
    event: React.SyntheticEvent<HTMLIFrameElement, Event>
  ) => {
    setHasError(true);
    console.error("Error loading video:", event);
  };

  return (
    <div className="flex flex-col">
      <div
        className={`min-h-[145px] min-w-[300px] w-full h-full relative overflow-hidden rounded-none ${className}`}
      >
        <p className="absolute p-1 text-white bg-black bg-opacity-50 rounded bottom-2 left-2">
          {title}
        </p>
        {!isPlaying && (
          <div
            className="absolute inset-0 flex items-center justify-center cursor-pointer"
            onClick={handlePlay}
          >
            <img
              src={coverphoto}
              alt={title}
              className="object-cover w-full h-full"
            />
            <button
              className="absolute flex items-center justify-center w-10 h-10 p-1 transition-opacity bg-white rounded-full opacity-75 hover:opacity-100"
              aria-label="Play"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-10 h-10 text-gray-800"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M8 5v14l11-7z" />
              </svg>
            </button>
          </div>
        )}
        {isPlaying && (
          <iframe
            src={videoUrl}
            className="absolute inset-0 w-full h-full"
            frameBorder="0"
            allowFullScreen
            onError={handleVideoError}
          >
            Your browser does not support iframes.
          </iframe>
        )}
        {hasError && (
          <div className="absolute inset-0 flex items-center justify-center text-white bg-black bg-opacity-75">
            <p>Error loading video</p>
          </div>
        )}

        {showEllipsis && (
          <ArtistDropDown
            tagList={tagList}
            boardId={boardId}
            itemId={itemId}
            itemType={"video"}
            caption={caption}
            coverId={coverId}
            imgSrc={coverphoto}
          >
            <img className="absolute top-0 right-0 p-2" src="/ellipsis.svg" />
          </ArtistDropDown>
        )}
      </div>

      {showCaption && (caption ? <CaptionItem caption={caption} /> : null)}
    </div>
  );
};

export default VideoType;
