import useAuth from "@/hooks/useAuth";
import React from "react";
// import { Outlet } from "react-router-dom";
//dont't implament the outlet it will run in locally but will get crash in the production
const ProtectedLayout = ({ children }: { children: React.ReactNode }) => {
  const { isLogin } = useAuth();

  if (!isLogin) return;
  return children;
};

export default ProtectedLayout;
