import React, { ReactNode, useState } from "react";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { Drawer, DrawerContent, DrawerHeader } from "@/components/ui/drawer";
import "react-toastify/dist/ReactToastify.css";
import { cn } from "@/lib/utils";
import { useMediaQuery } from "@mui/material";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import useBoard from "@/apis/board";
import toast from "react-hot-toast";
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import {
  CreateBoardResponse,
  GetSingleBoardResponse,
} from "@/types/boards.type";
import { CreateBoardParams } from "@/hooks/useFilter";

interface SingleCardDropDownProp {
  children: ReactNode;
  boardId: string;
  pin?: boolean;
}

const SingleCardDropDown: React.FC<SingleCardDropDownProp> = ({
  children,
  boardId,
  pin,
}) => {
  const { deleteSingleBoard, editSingleBoard, duplicateBoard } = useBoard();
  // State to manage the open status of the dropdown or drawer
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false); // State for managing the spinner
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const queryClient = useQueryClient();

  // Mutation for deleting a single board
  const deleteMutation: UseMutationResult<
    GetSingleBoardResponse,
    Error,
    string
  > = useMutation({
    mutationFn: (boardId: string) => deleteSingleBoard(boardId),
    onMutate: () => {
      setLoading(true); // Show spinner when mutation starts
    },
    onSuccess: (data) => {
      toast.success(`Board deleted successfully: ${data.message}`);
      queryClient.invalidateQueries({ queryKey: ["boards"] });
      setOpen(false);
      setLoading(false); // Stop loading when the mutation is successful
    },
    onError: (error: Error) => {
      toast.error(`Failed to delete board: ${error.message}`);
    },
    onSettled: () => {
      setLoading(false); // Hide spinner when mutation settles (success or error)
    },
  });

  // Function to handle board deletion
  const handleDelete = () => {
    deleteMutation.mutate(boardId);
  };

  // Mutation for pinning a board
  const pinMutation: UseMutationResult<
    GetSingleBoardResponse,
    Error,
    { boardId: string; params: CreateBoardParams }
  > = useMutation({
    mutationFn: ({ boardId, params }) => editSingleBoard({ boardId, params }),
    onMutate: () => {
      setLoading(true); // Show spinner when mutation starts
    },
    onSuccess: () => {
      toast.success("Pinned successfully!");
      queryClient.invalidateQueries({ queryKey: ["boards"] });
      setOpen(false); // Close the dropdown or drawer after successful pinning
      setLoading(false); // Stop loading when the mutation is successful
    },
    onError: (error: Error) => {
      toast.error("Failed to pin the board. Please try again.");
    },
    onSettled: () => {
      setLoading(false); // Hide spinner when mutation settles (success or error)
    },
  });

  // Function to handle pinning the board
  const handlePin = () => {
    const params: CreateBoardParams = {
      pinned: true,
    };

    pinMutation.mutate({
      boardId,
      params,
    });
  };

  const handleRemovePin = () => {
    const params: CreateBoardParams = {
      pinned: false,
    };

    pinMutation.mutate({
      boardId,
      params,
    });
  };

  const duplicateBoardMutation = useMutation<
    CreateBoardResponse,
    Error,
    string
  >({
    mutationFn: async (boardId) => {
      console.log("Duplicating board with ID:", boardId);
      try {
        const response = await duplicateBoard({ boardId }); // Wrap boardId in an object
        console.log("Response from duplicateBoard:", response);
        return response;
      } catch (error) {
        console.error("Error in duplicateBoard function:", error);
        throw error;
      }
    },
    onMutate: () => {
      setLoading(true); // Show spinner when mutation starts
    },
    onSuccess: (data: CreateBoardResponse) => {
      console.log("Board duplicated successfully:", data);
      toast.success(`Board "${data.data.name}" duplicated successfully!`);
      queryClient.invalidateQueries({ queryKey: ["boards"] });
      setLoading(false); // Stop loading when the mutation is successful
    },
    onError: (error: Error) => {
      // Use Error type directly
      console.error("Error duplicating board:", error.message);
      toast.error(`Failed to duplicate board: ${error.message}`);
    },
    onSettled: () => {
      setLoading(false); // Hide spinner when mutation settles (success or error)
    },
  });

  const handleDuplicateBoard = () => {
    if (boardId) {
      duplicateBoardMutation.mutate(boardId);
    }
  };

  return (
    <>
      {loading ? (
        <div className="loader-overlay">
          <div className="loader">
            <span className="spinner"></span>{" "}
          </div>
        </div>
      ) : isDesktop ? (
        <DropdownMenu open={open} onOpenChange={setOpen}>
          <DropdownMenuTrigger className="bg-transparent" asChild>
            {children}
          </DropdownMenuTrigger>
          <DropdownMenuContent className="border-[1px] border-bdr-10 border-solid min-w-[180px] rounded-none">
            <DropdownMenuGroup className="gap-2 text-base text-gray-60 font-sh5 flex flex-col">
              <ProfileForm
                onDelete={(e) => {
                  e.stopPropagation();
                  handleDelete();
                }}
                pin={(e) => {
                  e.stopPropagation();
                  handlePin();
                }}
                unpin={(e) => {
                  e.stopPropagation();
                  handleRemovePin();
                }}
                setOpen={setOpen}
                pinItem={pin ? pin : false}
                onDuplicate={(e) => {
                  e.stopPropagation();
                  handleDuplicateBoard();
                }}
              />
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      ) : (
        <Drawer open={open} onOpenChange={setOpen}>
          <DialogTrigger
            className="text-lg bg-transparent text-white"
            asChild
            onClick={() => setOpen(true)}
          >
            {children}
          </DialogTrigger>
          <DrawerContent className="rounded-none">
            <DrawerHeader className="p-0"></DrawerHeader>
            <ProfileForm
              pinItem={pin ? pin : false}
              onDelete={(e) => {
                e.stopPropagation();
                handleDelete();
              }}
              pin={(e) => {
                e.stopPropagation();
                handlePin();
              }}
              unpin={(e) => {
                e.stopPropagation();
                handleRemovePin();
              }}
              setOpen={setOpen}
              onDuplicate={(e) => {
                e.stopPropagation();
                handleDuplicateBoard();
              }}
            />
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};

type ProfileFormProps = {
  className?: string;
  onDelete: (e: any) => void;
  pin: (e: any) => void;
  unpin: (e: any) => void;
  setOpen: (open: boolean) => void;
  pinItem: boolean;
  onDuplicate: (e: any) => void;
};

function ProfileForm({
  className,
  onDelete,
  pin,
  setOpen,
  unpin,
  pinItem,
  onDuplicate,
}: ProfileFormProps) {
  return (
    <form
      className={cn(
        "grid items-center gap-4 mq450:gap-8 mq450:p-6 p-4",
        className,
      )}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
          setOpen(false);
          onDuplicate(e);
        }} // Close dropdown/drawer and handle duplication
        className="cursor-pointer font-normal text-gray-60 text-base mq450:text-left text-left font-sh5"
      >
        Duplicate
      </div>
      {pinItem ? (
        <div
          onClick={(e) => {
            e.stopPropagation();
            unpin(e); // Call unpin function when Unpin is clicked
          }}
          className="cursor-pointer font-normal text-gray-60 text-base mq450:text-left text-left font-sh5"
        >
          Unpin
        </div>
      ) : (
        <div
          onClick={(e) => {
            e.stopPropagation();
            pin(e); // Call pin function when Pin is clicked
          }}
          className="cursor-pointer font-normal text-gray-60 text-base mq450:text-left text-left font-sh5"
        >
          Pin
        </div>
      )}
      <div
        onClick={(e) => {
          e.stopPropagation();
          setOpen(false); // Close the dropdown or drawer before deleting
          onDelete(e); // Call onDelete function
        }}
        className="cursor-pointer font-normal text-gray-60 text-base mq450:text-left text-left font-sh5"
      >
        Delete
      </div>
    </form>
  );
}

export default SingleCardDropDown;
