import React, { lazy, Suspense } from "react";
import useGlobalApis from "@/apis/global";
import useFilter from "@/hooks/useFilter";
import Layout from "@/layout/DefaultLayout";
import { useQuery } from "@tanstack/react-query";
import LazyLoader from "@/components/LazyLoader";
const Preview = lazy(() => import("@/components/Preview/Preview"));
const Businesses = () => {
  const { filterOption } = useFilter({
    query: {
      sectionStatus: "publish",
    },
  });
  const { getFeed } = useGlobalApis();
  const feed = useQuery({
    queryKey: ["bussines-feed", filterOption],
    queryFn: () =>
      getFeed({
        params: filterOption,
        type: "business",
      }),
  });

  return (
    <Layout viewMobTopNav>
      <Suspense fallback={<LazyLoader />}>
        <Preview data={feed.data?.data} type="business" />
      </Suspense>
    </Layout>
  );
};

export default Businesses;
