import React, { createContext, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import useUserAPI, { UserInteraction } from "@/apis/user";

// Define the shape of the context value
interface UserContextValue {
  interactions: UserInteraction | undefined;
}

// Create the UserContext with a default value
const UserContext = createContext<UserContextValue>({
  interactions: undefined,
});

const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { getUserIntreaction } = useUserAPI();

  // Use useQuery to fetch user interactions
  const {
    data: interactions,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["user-interactions"],
    queryFn: () => getUserIntreaction(),
  });

  // Provide the fetched data (or undefined if still loading or errored) to the context
  return (
    <UserContext.Provider
      value={{
        interactions,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

// Export the context provider and the custom hook for consuming the context
export default UserContextProvider;
export const useUserContext = () => useContext(UserContext);
