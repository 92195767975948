import { apis } from ".";
import { QueryData } from "@/hooks/useFilter";
import { Section } from "@/types/atrists.type";

interface MetaData {
  properties: string[];
  medium: string;
  year: number | null;
  shape: string;
  signature: string;
  inscriptions: string[];
  technique: string;
  style: string;
  subject: string;
  _id: string;
  id: string;
}

export interface ArtObject {
  objectName: string;
  image: string;
  objectId: string;
  metaData: MetaData;
  price: number;
  assignProtect: boolean;
  overview: string[]; // Assuming overview is an array of strings, you can adjust this if it's different
  forSale: boolean;
  addedBy: string;
  publications: string[]; // Assuming publications is an array of strings, adjust if it's different
  createdAt: string; // Consider using `Date` type if this will be handled as a date object
  updatedAt: string; // Same as above
  isDeleted: boolean;
  isActive: boolean;
  id: string;
  _id: string;
}

const useObjects = () => {
  const getFeed = async (
    params: QueryData
  ): Promise<{
    data: Section[];
  }> => {
    try {
      const { data } = await apis.post("/client/api/v1/objects_section/list", {
        data: params,
      });
      return data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const getObjectById = async ({ id }: { id: string }): Promise<ArtObject> => {
    try {
      const { data } = await apis.get(`client/api/v1/objects/${id}`, {});
      return data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return { getFeed, getObjectById };
};

export default useObjects;
