import React, { ReactNode, useState } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer";
import { useMediaQuery } from "@mui/material";
import { Button } from "@/components/ui/button";
import useBoard from "@/apis/board";
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import toast from "react-hot-toast";
import { GetSingleBoardResponse } from "@/types/boards.type";
import { UpdateItemParams } from "@/hooks/useFilter";

type AddCaptionProps = {
  children: ReactNode;
  boardId: string;
  itemId: string;
  captionImg: string;
};

const AddCaption: React.FC<AddCaptionProps> = ({
  children,
  boardId,
  itemId,
  captionImg,
}) => {
  const [open, setOpen] = useState(false);
  const { editItem } = useBoard();
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [caption, setCaption] = useState("");
  const [saving, setSaving] = useState(false);
  const queryClient = useQueryClient();

  const updateCaptionMutation: UseMutationResult<
    GetSingleBoardResponse,
    Error,
    UpdateItemParams
  > = useMutation({
    mutationFn: (params: UpdateItemParams) => editItem({ params }), // Call editItem with parameters
    onMutate: () => {
      setSaving(true);
    },
    onSuccess: (data) => {
      setSaving(false);
      toast.success(`Item updated successfully: ${data.message}`); // Notify on success
      queryClient.invalidateQueries({
        queryKey: ["single-board"],
      });
      setOpen(false);
    },
    onError: (error: Error) => {
      setSaving(false);
      toast.error(`Failed to update item: ${error.message}`); // Notify on error
    },
  });
  const handleSave = async () => {
    // Prepare the UpdateItemRequest object
    const updateRequest: UpdateItemParams = {
      boardId,
      itemId,
      updatedFields: {
        caption, // Pass the current caption state
      },
    };

    try {
      // Trigger the mutation with updateRequest
      await updateCaptionMutation.mutateAsync(updateRequest); // Use mutateAsync for async handling
    } catch (error) {
      console.error("Error saving data:", error);
      toast.error("Error occurred while saving.");
    }
  };
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  return isDesktop ? (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger onClick={handleClick} className="bg-transparent" asChild>
        {children}
      </DialogTrigger>

      <DialogContent
        onClick={(e) => e.stopPropagation()}
        className="min-w-[969px]"
      >
        <DialogHeader className="px-8 ">
          <DialogTitle className=" w-full border-solid text-gray-100 font-normal font-sh5 text-[28px]">
            <div className="flex flex-row items-center justify-end">
              <p className="mr-[420px]"> Add Caption</p>
              <DialogClose asChild className="bg-transparent">
                <img className="cursor-pointer" src="/close1.svg" />
              </DialogClose>
            </div>
          </DialogTitle>
        </DialogHeader>
        <ProfileForm
          saving={saving}
          onSave={handleSave}
          caption={caption}
          setCaption={setCaption}
          captionImg={captionImg}
        />
      </DialogContent>
    </Dialog>
  ) : (
    <Drawer open={open} onOpenChange={setOpen}>
      <DialogTrigger
        onClick={handleClick}
        className="text-lg text-white bg-transparent"
      >
        {children}
      </DialogTrigger>
      <DrawerContent
        onClick={(e) => e.stopPropagation()}
        className="w-full rounded-none h-2/3"
      >
        <DrawerHeader className="px-5 text-left">
          <DrawerTitle className="text-5xl font-normal text-gray-100 font-sh5">
            <div className="flex flex-row items-center justify-between">
              Add Caption
              <DialogClose className="bg-transparent">
                <img className="cursor-pointer" src="/close1.svg" />
              </DialogClose>
            </div>
          </DrawerTitle>
        </DrawerHeader>{" "}
        <ProfileForm
          onSave={handleSave}
          caption={caption}
          setCaption={setCaption}
          captionImg={captionImg}
          saving={saving}
        />
      </DrawerContent>
    </Drawer>
  );
};

type ProfileFormProps = {
  onSave: () => void; // Accepts a MouseEvent parameter
  className?: string;
  caption: string; // Caption state
  captionImg: string; // Caption state
  saving: boolean;
  setCaption: React.Dispatch<React.SetStateAction<string>>; // Setter for caption
};

function ProfileForm({
  onSave,
  caption,
  setCaption,
  captionImg,
  saving,
}: ProfileFormProps) {
  const maxCharacters = 500;

  const charactersLeft = maxCharacters - caption.length;
  return (
    <form
      className={`mq450:overflow-auto mq450:w-full mq750:w-full mq750:overflow-auto h-full flex w-full flex-col justify-between`}
    >
      <div className="flex flex-col ">
        <div className="flex flex-row items-center justify-between h-full mq750:flex-col mq450:flex-col">
          <div className="w-1/3 pb-4 mq450:w-full mq750:scale px-11">
            <img className="flex w-full" src={captionImg} alt={captionImg} />
          </div>
          <div className="flex flex-col items-start justify-start w-2/3 h-full mq450:w-full mq750:w-full">
            <div className="w-full h-full px-8 mq450:pb-3 mq750:pb-3 mq450:px-5 mq750:px-5 mq450:flex mq450:flex-grow mq750:flex mq750:flex-grow mq750:justify-center">
              <textarea
                className="font-sh5 resize-none w-full text-sm font-normal h-full font-base text-gray-60 px-10 mq750:py-5 mq750:w-[70vw] mq750:px-5 mq750:min-h-[20px] mq450:py-5 mq450:px-5 mq450:min-h-[20px] border-solid border-[1px] border-bdr-10 rounded-none py-5"
                placeholder="add caption"
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
              />
            </div>
            <div className="w-full px-8 pt-2 pb-5 text-sm font-normal text-left text-gray-500 mq450:px-5 mq750:px-5 font-sh5">
              {charactersLeft} characters left
            </div>
          </div>
        </div>

        <div className="flex justify-end ">
          <div className="flex flex-row justify-between w-2/3 mq450:py-4 mq450:w-full mq750:w-full items-center px-5 py-8 bg-[#F2F2F2]">
            <DialogClose asChild>
              <button
                disabled={saving}
                className="cursor-pointer h-6 min-w-12 font-medium text-sm relative font-sh5 text-gray-100 border-gray-100 border-b-[1px] border-solid bg-transparent rounded-none"
                type="button"
              >
                CANCEL
              </button>
            </DialogClose>
            <Button
              disabled={saving}
              className="bg-other-cta rounded-[50px] min-w-[130px] h-[42px] px-4 font-medium text-sm text-white font-sh5"
              variant="default"
              type="button"
              onClick={onSave}
            >
              {saving ? "SAVING..." : "SAVE"}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default AddCaption;
