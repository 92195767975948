import React from "react";
import { Link, useLocation } from "react-router-dom";

const BottomNavBar = () => {
  const location = useLocation();

  const getActiveItem = () => {
    switch (location.pathname) {
      case "/":
        return "discover";
      case "/boards":
        return "boards";
      case "www.google.com":
        return "catalogue";
      case "/profile":
        return "profile";
      default:
        return "discover";
    }
  };

  const menuActiveItem = getActiveItem();
  return (
    <div className="fixed bottom-0 left-0 z-10 hidden w-full h-20 py-4 mq450:block  mq750:block bg-other-cta">
      <div className="flex flex-row items-center justify-between px-4">
        <div>
          <Link to="/discover/feed">
            <div className="flex flex-col items-center justify-center gap-2">
              <img
                className="w-6 h-6"
                src={
                  menuActiveItem === "discover"
                    ? "/discoverSelected.svg"
                    : "/searchicon.svg"
                }
                alt="Discover"
              />
              <p className="font-plus-jakarta-sans text-xs font-normal text-[#CCCCCC]">
                Discover
              </p>
            </div>
          </Link>
        </div>
        <div>
          <Link to="/boards">
            <div className="flex flex-col items-center justify-center gap-2">
              <img
                className="w-6 h-6"
                src={
                  menuActiveItem === "boards"
                    ? "/boardsSelected.svg"
                    : "/boardUnselected.svg"
                }
                alt="Boards"
              />
              <p className="font-plus-jakarta-sans text-xs font-normal text-[#CCCCCC]">
                Boards
              </p>
            </div>
          </Link>
        </div>
        <div>
          <a href="www.asign.art/catalogue" target="_blank">
            <div className="flex flex-col items-center justify-center gap-2">
              <img
                className="w-6 h-6"
                src={
                  menuActiveItem === "catalogue"
                    ? "/catalogueSelected.svg"
                    : "/catalogueUnselected.svg"
                }
                alt="Profile"
              />
              <p className="font-plus-jakarta-sans text-xs font-normal text-[#CCCCCC]">
                Catalogue
              </p>
            </div>
          </a>
        </div>
        <div>
          <Link to="/profile">
            <div className="flex flex-col items-center justify-center gap-2">
              <img
                className="w-6 h-6"
                src={
                  menuActiveItem === "profile"
                    ? "/personSelected.svg"
                    : "/person.svg"
                }
                alt="Catalogue"
              />
              <p className="text-xs font-plus-jakarta-sans font-normal text-[#CCCCCC]">
                Profile
              </p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BottomNavBar;
