import { apis } from ".";
import {
  AddItemParams,
  CreateBoardParams,
  DeleteItemParam,
  QueryOptions,
  searchQueryOption,
  TagParams,
  UpdateItemParams,
  similarItemParams,
  duplicateBoardParams,
} from "@/hooks/useFilter";
import {
  AddItemResponse,
  CreateBoardResponse,
  GetAllBoardResponse,
  GetSingleBoardResponse,
  moreLikeThis,
  TagResponse,
} from "@/types/boards.type";

const useBoard = () => {
  const createBoard = async (
    params: CreateBoardParams,
  ): Promise<CreateBoardResponse> => {
    try {
      const { data } = await apis.post("/client/api/v1/board/create", {
        data: params,
      });
      return data;
    } catch (error) {
      throw error;
    }
  };
  const getAllBoard = async ({
    params,
  }: {
    params: QueryOptions;
  }): Promise<GetAllBoardResponse> => {
    try {
      const response = await apis.post("/client/api/v1/board/list", {
        data: params,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getSingleBoard = async (
    boardId: string,
    params: searchQueryOption,
  ): Promise<GetSingleBoardResponse> => {
    try {
      const response = await apis.get(`/client/api/v1/board/${boardId}`, {
        params,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const editSingleBoard = async ({
    boardId,
    params,
  }: {
    boardId: string;
    params: CreateBoardParams;
  }): Promise<GetSingleBoardResponse> => {
    try {
      const response = await apis.put(
        `/client/api/v1/board/update/${boardId}`,
        {
          data: params,
        },
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const editItem = async ({
    params,
  }: {
    params: UpdateItemParams;
  }): Promise<GetSingleBoardResponse> => {
    try {
      const response = await apis.put("/client/api/v1/board/updateItem", {
        data: params,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const deleteSingleBoard = async (
    boardId: string,
  ): Promise<GetSingleBoardResponse> => {
    try {
      const response = await apis.delete(
        `/client/api/v1/board/delete/${boardId}`,
        {},
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to delete board");
    }
  };

  const addItem = async (params: AddItemParams): Promise<AddItemResponse> => {
    try {
      const response = await apis.post(`/client/api/v1/board/addItem`, {
        data: params,
      });
      if (!response || !response.data) {
        throw new Error("Invalid response from the server");
      }
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error(`Failed to add item: ${error}`);
    }
  };

  const getTag = async (params: TagParams): Promise<TagResponse> => {
    try {
      const response = await apis.post(
        `/client/api/v1/contentlibrary/tagslist`,
        {
          data: params,
        },
      );
      if (!response || !response.data) {
        throw new Error("Invalid response from the server");
      }
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error(`Failed to add item: ${error}`);
    }
  };
  const deleteItem = async (
    params: DeleteItemParam,
  ): Promise<AddItemResponse> => {
    try {
      const response = await apis.delete(`/client/api/v1/board/deleteItem`, {
        data: params,
      });
      if (!response || !response.data) {
        throw new Error("Invalid response from the server");
      }
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error(`Failed to add item: ${error}`);
    }
  };
  const getsimilarItemsList = async (
    params: similarItemParams,
  ): Promise<moreLikeThis> => {
    try {
      const response = await apis.post(
        "/client/api/v1/board/similarItemsList",
        {
          data: params,
        },
      );

      if (!response || !response.data) {
        throw new Error("Invalid response from the server");
      }

      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error(`Failed to fetch similar items: ${error}`);
    }
  };

  const duplicateBoard = async (
    params: duplicateBoardParams,
  ): Promise<CreateBoardResponse> => {
    try {
      const response = await apis.post("/client/api/v1/board/duplicate", {
        data: params,
      });

      if (!response || !response.data) {
        throw new Error("Invalid response from the server");
      }

      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error(`Failed to fetch similar items: ${error}`);
    }
  };
  return {
    createBoard,
    getAllBoard,
    getSingleBoard,
    editSingleBoard,
    deleteSingleBoard,
    addItem,
    deleteItem,
    editItem,
    getTag,
    getsimilarItemsList,
    duplicateBoard,
  };
};
export default useBoard;
