import { cn } from "@/lib/utils";
import React from "react";
import TextDropDown from "./TextDropDown";
import CaptionItem from "./CaptionItem";

interface TextProp {
  title?: string;
  summary?: string;
  className?: string;
  boardId: string;
  itemId: string;
  tagList: string[];
  itemType: string;
  caption?: string;
  showCaption?: boolean;
  showEllipsis?: boolean;
}

const Text: React.FC<TextProp> = ({
  title,
  summary,
  className,
  boardId,
  itemId,
  itemType,
  caption,
  showEllipsis = true,
  showCaption = true,
  tagList,
}) => {
  return (
    <>
      <div className="bg-bg-5 flex-col px-[21px] w-full py-4 flex relative">
        {title && (
          <p
            className={cn("text-black font-sh5 font-medium text-lg", className)}
          >
            {title}
          </p>
        )}
        {summary && (
          <p className="text-base font-normal text-black font-sh5 line-clamp-6">
            {summary}
          </p>
        )}
        {showEllipsis && (
          <TextDropDown
            boardId={boardId}
            itemId={itemId}
            itemType={itemType}
            caption={caption}
            coverId={""}
            tagList={tagList}
          >
            <img
              className="absolute top-0 right-0 p-2 cursor-pointer"
              src="/ellipsis.svg"
              alt="Options"
            />
          </TextDropDown>
        )}
      </div>
      {showCaption && (caption ? <CaptionItem caption={caption} /> : null)}
    </>
  );
};

export default Text;
