import React, { ReactNode, useState, useMemo } from "react";

import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { DialogClose } from "@radix-ui/react-dialog";
import { useQuery } from "@tanstack/react-query";
import Loader from "../Global/Loader";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  moreLikeThis,
  ObjectItem,
  ArtistItem,
  BusinessItem,
  EventItem,
  VideoItem,
  BoardItemType,
  WritingItem,
} from "@/types/boards.type";
// Import child components
// import Catalogue from "./MoreLikeThis/Catalogue";
import All from "./MoreLikeThis/All";
import Artists from "./MoreLikeThis/Artists";
import Events from "./MoreLikeThis/Events";
import Videos from "./MoreLikeThis/Videos";
import Writing from "./MoreLikeThis/Writing";
import Objects from "./MoreLikeThis/Objects";
import Business from "./MoreLikeThis/Business";
import useBoard from "@/apis/board";
import { useParams } from "react-router-dom";
import useFilter from "@/hooks/useFilter";

interface MoreLikeThisProp {
  children: ReactNode;
}

const itemTypes: { [key: string]: string } = {
  Objects: "object",
  Artists: "artist",
  Businesses: "business",
  Events: "events",
  Videos: "video",
  Writing: "writing",
  Catalogue: "catalogue",
  All: "",
};
const MoreLikeThis: React.FC<MoreLikeThisProp> = ({ children }) => {
  const [activeComponent, setActiveComponent] = useState<string>("All");
  const { boardId } = useParams<{ boardId: string }>();
  const [searchQuery, setSearchQuery] = useState("");
  const { filterOption } = useFilter({
    options: {
      pagination: false,
    },
  });
  const { getsimilarItemsList } = useBoard();
  // Fetch similar items using the useQuery hook
  const { data, isLoading, error } = useQuery<moreLikeThis>({
    queryKey: ["similarItemsList", boardId, searchQuery, activeComponent],
    queryFn: () =>
      getsimilarItemsList({
        query: {
          boardId: boardId!,
          itemType: itemTypes[activeComponent] || "",
        },
      }),
  });

  // Filter the data based on searchQuery and activeComponent
  const matchesSearch = (item: BoardItemType, search: string): boolean => {
    if (!search) return true; // If no search query, match all

    const searchLower = search.toLowerCase();
    switch (item.itemType) {
      case "object":
        return item.objectName.toLowerCase().includes(searchLower);
      case "artist":
        return item.name.toLowerCase().includes(searchLower);
      case "business":
        return item.businessName.toLowerCase().includes(searchLower);
      case "events":
        return item.eventName.toLowerCase().includes(searchLower);
      case "video":
        return item.content?.title.toLowerCase().includes(searchLower);
      default:
        return false;
    }
  };

  // Filter the data based on searchQuery and activeComponent
  const filteredData = useMemo(() => {
    if (!data?.data) return [];

    return data?.data.filter((item) => {
      const isActive =
        activeComponent === "All" ||
        item.itemType === itemTypes[activeComponent];
      return isActive && matchesSearch(item, searchQuery);
    });
  }, [data, searchQuery, activeComponent]);

  // Cast filteredData to specific item type arrays
  const filteredObjects = filteredData.filter(
    (item) => item.itemType === "object"
  ) as ObjectItem[];
  const filteredArtists = filteredData.filter(
    (item) => item.itemType === "artist"
  ) as ArtistItem[];
  const filteredBusinesses = filteredData.filter(
    (item) => item.itemType === "business"
  ) as BusinessItem[];
  const filteredEvents = filteredData.filter(
    (item) => item.itemType === "events"
  ) as EventItem[];
  const filteredVideos = filteredData.filter(
    (item) => item.itemType === "video"
  ) as VideoItem[];
  const filteredWriting = filteredData.filter(
    (item) => item.itemType === "writing"
  ) as WritingItem[];
  const filteredAll = filteredData as BoardItemType[];

  // Render the appropriate component based on activeComponent
  const renderComponent = () => {
    if (isLoading) return <Loader />;
    if (error) return <div>Error loading data...</div>;
    if (filteredData.length === 0) {
      return (
        <div className="py-4 text-center text-gray-500">No items found</div>
      );
    }

    switch (activeComponent) {
      case "Objects":
        return filteredObjects.map((item) => (
          <div key={item._id} className="masonry-item">
            <Objects items={[item]} />
          </div>
        ));
      case "Artists":
        return filteredArtists.map((item) => (
          <div key={item._id} className="masonry-item">
            <Artists items={[item]} />
          </div>
        ));
      case "Businesses":
        return filteredBusinesses.map((item) => (
          <div key={item._id} className="masonry-item">
            <Business items={[item]} />
          </div>
        ));
      case "Events":
        return filteredEvents.map((item) => (
          <div key={item._id} className="masonry-item">
            <Events items={[item]} />
          </div>
        ));
      case "Videos":
        return filteredVideos.map((item) => (
          <div key={item._id} className="masonry-item">
            <Videos items={[item]} />
          </div>
        ));
      case "Writing":
        return filteredWriting.map((item) => (
          <div key={item._id} className="masonry-item">
            <Writing items={[item]} />
          </div>
        ));
      case "All":
        return filteredAll.map((item) => (
          <div key={item._id} className="masonry-item">
            <All items={[item]} />
          </div>
        ));
      default:
        return null;
    }
  };

  const buttonClass = (label: string) =>
    `px-5 py-3 rounded-[50px] text-xs font-sh5 font-normal cursor-pointer border-solid border-[1px] ${
      activeComponent === label
        ? "bg-gray-100 text-white"
        : "bg-white text-gray-60 border-gray-20"
    }`;
  const heading = searchQuery
    ? `Search Results: ${searchQuery}`
    : "More like this";
  return (
    <div>
      <Drawer>
        <DrawerTrigger asChild>{children}</DrawerTrigger>
        <DrawerContent className="rounded-none p-5 h-[90%] mq750:h-4/5 mq450:h-4/5 lg:mx-[77px]">
          <DrawerHeader className="p-0 mq750:p-0 mq450:p-0 ">
            <DrawerTitle className="border-b-[1px] pb-5 border-gray-20 border-solid font-normal font-sh5 text-[28px] text-gray-100">
              <div className="flex flex-row items-center justify-between ">
                <p className="md:mt-2 mq750:text-5xl"> {heading}</p>
                <div className="flex flex-row items-center gap-5">
                  <div className="relative mq750:hidden mq450:hidden">
                    <input
                      className="w-full border-[1px] min-w-[232px] pl-12 border-solid border-gray-20 h-10 rounded-[50px] font-sh5 font-normal text-sm"
                      placeholder="Search"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <img
                      className="absolute transform -translate-y-1/2 left-4 top-6 mt-[2px]"
                      src="/iconsearch.svg"
                      alt="Search Icon"
                    />
                  </div>
                  <DialogClose asChild>
                    <img
                      className="cursor-pointer md:mt-2"
                      src="/close1.svg"
                      alt="Close Icon"
                    />
                  </DialogClose>
                </div>
              </div>
            </DrawerTitle>
            <div className="mq750:pt-6 mq450:pt-6">
              <div className="hidden mq750:relative mq450:relative mq750:block mq450:block">
                <input
                  className="w-full border-[1px] min-w-[232px] pl-12 border-solid border-gray-20 h-10 rounded-[50px]"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <img
                  className="absolute transform -translate-y-1/2 left-4 top-1/2"
                  src="/iconsearch.svg"
                  alt="Search Icon"
                />
              </div>
            </div>
          </DrawerHeader>
          <div className="">
            <div className="flex flex-col">
              <div className="flex flex-row items-center justify-center my-6 gap-x-2 mq450:overflow-x-auto mq750:overflow-x-auto mq750:justify-start mq750:items-start mq750:whitespace-nowrap mq450:justify-start mq450:items-start mq450:whitespace-nowrap">
                <button
                  className={buttonClass("All")}
                  onClick={() => setActiveComponent("All")}
                >
                  All
                </button>
                <button
                  className={buttonClass("Objects")}
                  onClick={() => setActiveComponent("Objects")}
                >
                  Objects
                </button>
                <button
                  className={buttonClass("Artists")}
                  onClick={() => setActiveComponent("Artists")}
                >
                  Artists
                </button>
                <button
                  className={buttonClass("Businesses")}
                  onClick={() => setActiveComponent("Businesses")}
                >
                  Businesses
                </button>
                <button
                  className={buttonClass("Events")}
                  onClick={() => setActiveComponent("Events")}
                >
                  Events
                </button>
                <button
                  className={buttonClass("Videos")}
                  onClick={() => setActiveComponent("Videos")}
                >
                  Videos
                </button>
                <button
                  className={buttonClass("Writing")}
                  onClick={() => setActiveComponent("Writing")}
                >
                  Writing
                </button>
                <button
                  className={buttonClass("Catalogue")}
                  onClick={() => setActiveComponent("Catalogue")}
                >
                  Catalogue
                </button>
              </div>

              <ScrollArea className="overflow-auto mq750:max-h-[50vh] max-h-[70vh] lg:px-[76px] ">
                <div className="masonry-layout">{renderComponent()}</div>
              </ScrollArea>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default MoreLikeThis;
