import business from "@/apis/business";
import useBusiness from "@/apis/business";
import Header from "@/components/Global/header/Header";
import { MobileHeader } from "@/components/Global/header/MobileHeader";
import Loader from "@/components/Global/Loader";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbPage,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { Switch } from "@/components/ui/switch";
import { cn } from "@/lib/utils";
import { Represent } from "@/types/user.types";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";

import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

const sideBar = [
  { label: "Biography" },
  { label: "Catalogue rasionné" },
  { label: "Representation" },
  { label: "Education" },
  { label: "Events" },
  { label: "Recognition" },
  { label: "Press" },
  { label: "Notable collections" },
  { label: "Publications" },
];
const BusinessDetail = () => {
  const { id } = useParams() as { id: string };
  const { getBusinessById } = useBusiness();

  const [currentSection, setCurrentSection] = useState<string>("");

  const refs = sideBar.reduce((acc, value) => {
    acc[value.label] = useRef<HTMLDivElement>(null);
    return acc;
  }, {} as { [key: string]: React.RefObject<HTMLDivElement> });

  const scrollToSection = (label: string) => {
    const ref = refs[label]?.current;

    if (ref) {
      setCurrentSection(label);
      ref.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "-10px",
      threshold: 0.7,
    };

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCurrentSection(entry.target.getAttribute("data-section") || "");
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    Object.values(refs).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [refs]);

  const { data: business, isLoading } = useQuery({
    queryKey: ["business", id],
    queryFn: () => getBusinessById({ id }),
    enabled: !!id,
  });

  if (isLoading) return <Loader />;

  return (
    <>
      <Header />
      <div className="container font-normal font-sh5">
        <div className="fixed z-10 w-full top-15">
          <MobileHeader
            data={sideBar}
            active={currentSection}
            onClick={scrollToSection}
          />
        </div>
        <Breadcrumb className="p-5 ">
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href="/">Discover</BreadcrumbLink>
            </BreadcrumbItem>
            <span className="text-black">/</span>
            <BreadcrumbItem>
              <BreadcrumbLink href="/business/feed">business</BreadcrumbLink>
            </BreadcrumbItem>
            <span className="text-black">/</span>
            <BreadcrumbItem>
              <BreadcrumbPage>{business?.businessName}</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        <div className="flex flex-col-reverse gap-5 mt-8 md:flex-row md:items-center bg-[#F2F2F2] p-5">
          <div className="lg:w-1/3">
            <Avatar className="w-[100px] h-[100px]">
              <AvatarImage src={business?.image} alt={business?.businessName} />
              <AvatarFallback>
                {business?.businessName?.slice(0, 1) ?? "CN"}
              </AvatarFallback>
            </Avatar>
            <h2 className="font-normal font-eb md:text-[48px] text-gray-80 text-[36px]">
              {business?.businessName}
            </h2>
            <div className="flex items-stretch gap-4 text-gray-60">
              <span>
                b. {format(business?.businessDate ?? new Date(), "yyyy")}
              </span>
              <span>{business?.businessLocation}</span>
            </div>
            <div className="flex items-center gap-4 mt-[26px]">
              <Button className="rounded-full">FOLLOWING</Button>
              <p className="text-[#696969]">2.3k Followers</p>
            </div>
          </div>
          <div className="lg:w-2/3">
            <img
              src="/demo-image.png"
              alt=""
              className="w-full max-h-[437px] object-cover object-bottom"
            />
          </div>
        </div>
        <div className="relative flex p-5 mt-5">
          <div className="hidden lg:w-1/3 md:block">
            <div className="overflow-y-scroll text-gray-60">
              {sideBar.map((item) => (
                <div
                  role="button"
                  key={item.label}
                  tabIndex={0}
                  className={cn(
                    "mb-[20px] cursor-pointer whitespace-nowrap hover:text-gray-100",
                    currentSection === item.label && "text-gray-100"
                  )}
                  onClick={() => scrollToSection(item.label)}
                >
                  {item.label}
                </div>
              ))}
            </div>
          </div>
          <div className="lg:w-2/3 lg:max-h-[500px] overflow-y-scroll">
            <div ref={refs["Biography"]} data-section="Biography">
              <h3 className="font-medium text-[24px]">Biography</h3>
              <p className="my-5">{business?.description}</p>
              <Button variant="outline" className="rounded-full">
                READ MORE
              </Button>
            </div>
            <Separator className="my-[42px]" />
            <div
              ref={refs["Catalogue rasionné"]}
              data-section="Catalogue rasionné"
            >
              <div className="flex items-center justify-between mb-[32px]">
                <h3 className="font-medium text-[24px]">
                  Catalogue raisonné <sup>{0}</sup>
                </h3>
                <p className="items-center hidden gap-2 text-sm lg:flex">
                  Objects for sale <Switch />
                </p>
              </div>
              <div className="flex flex-wrap items-center justify-between gap-y-10">
                {/* {business?.gallery.map((item) => (
                  <WorkCard
                    key={item._id}
                    image={item.art_url}
                    description={item.art_desc}
                    label={item.art_name}
                  />
                ))} */}
              </div>
              <Button variant="outline" className="mt-10 rounded-full">
                VIEW MORE
              </Button>
            </div>
            <Separator className="my-[42px]" />
            <div ref={refs["Representation"]} data-section="Representation">
              <h3 className="font-medium text-[24px]">Representation</h3>
              <div>
                <Representation data={business?.represents || []} />
              </div>
            </div>

            <Separator className="my-[42px]" />
            <div ref={refs["Events"]} data-section="Events">
              <h3 className="font-medium text-[24px]">Events</h3>
              <div className="grid grid-cols-2 mt-10 gap-y-10">
                {business?.relatedEvents.map((item) => (
                  <div key={item.id}>
                    <img
                      src="https://picsum.photos/300/200"
                      alt={item.eventName}
                    />
                    <div className="grid gap-1 mt-5">
                      <h4 className="font-normal capitalize">
                        {item.eventName}
                      </h4>
                      <p className="text-sm text-gray-60">{item.country}</p>
                      <p className="text-sm text-gray-60">
                        {format(new Date(item.startDate), "MMM d, yyyy")} -{" "}
                        {format(new Date(item.endDate), "MMM d, yyyy")}{" "}
                      </p>
                      <p className="text-sm text-gray-60">
                        {" "}
                        {format(new Date(item.startDate), "h:mm a")} -{" "}
                        {format(new Date(item.endDate), "h:mm a")}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <Separator className="my-[42px]" />
            <div ref={refs["Recognition"]} data-section="Recognition">
              <h3 className="font-medium text-[24px]">Recognition</h3>
              <div className="grid grid-cols-2 mt-10 gap-y-10">
                {business?.recognition.map((item) => (
                  <div key={item.id}>
                    <p>{item.artName}</p>
                    <p className="text-sm text-gray-60">1994 - 1997</p>
                  </div>
                ))}
              </div>
              <Button variant="outline" className="mt-10 rounded-full">
                VIEW MORE
              </Button>
            </div>
            <Separator className="my-[42px]" />
            <div ref={refs["Press"]} data-section="Press">
              <h3 className="font-medium text-[24px]">Press</h3>
              <div className="grid grid-cols-2 mt-10 gap-y-10">
                {business?.press.map((item) => (
                  <div key={item.id}>
                    <p>{item.articleName}</p>
                    <p className="my-1 text-sm text-gray-60">
                      {format(new Date(item.articleDate), "yyyy")}
                    </p>
                    <a
                      className="underline"
                      href={item.articleLink}
                      target="blank"
                    >
                      VIEW IMAGE
                    </a>
                  </div>
                ))}
              </div>
              <Button variant="outline" className="mt-10 rounded-full">
                VIEW MORE
              </Button>
            </div>

            <Separator className="my-[42px]" />
            <div ref={refs["Publications"]} data-section="Publications">
              <h3 className="font-medium text-[24px]">Publications</h3>
              <div className="grid grid-cols-2 mt-10 gap-y-10">
                {business?.publications.map((item) => (
                  <div key={item.id}>
                    <img
                      src="https://picsum.photos/300/200"
                      alt={item.publicationName}
                    />
                    <p>{item.publicationName}</p>
                    <p className="mt-1 text-sm text-gray-60">
                      {item.publicationBusiness} -{" "}
                      {format(new Date(item.publicationDate), "yyyy")}
                    </p>
                  </div>
                ))}
              </div>
              <Button variant="outline" className="mt-10 rounded-full">
                VIEW MORE
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessDetail;

const Representation: React.FC<{ data: Represent[] }> = ({ data }) => {
  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  const verifiedData = data.filter((item) => item.verified);
  const unverifiedData = data.filter((item) => !item.verified);
  const [verify, setVerify] = useState(true);

  return (
    <>
      <ul className="flex gap-5 my-10 list-none border-b border-solid border-gray-20">
        <li
          className={`pb-1 cursor-pointer ${
            verify ? "border-b border-gray-100 border-solid" : "text-gray-60"
          }`}
          onClick={() => setVerify(true)}
        >
          Verified
        </li>
        <li
          className={`cursor-pointer ${
            !verify ? "border-b border-gray-100 border-solid" : "text-gray-60"
          }`}
          onClick={() => setVerify(false)}
        >
          Unverified
        </li>
      </ul>
      <div className="grid grid-cols-2 gap-y-10">
        {(verify ? verifiedData : unverifiedData).map((item, index) => (
          <div className="flex items-center gap-5" key={index}>
            <Avatar>
              <AvatarImage src={item.image} alt={item.name} />
              <AvatarFallback>{item.name[0]}</AvatarFallback>
            </Avatar>
            <div>
              <p>{item.name}</p>
              <p className="text-sm text-gray-60">{item.location}</p>
            </div>
          </div>
        ))}
      </div>
      <Button variant="outline" className="mt-10 rounded-full">
        VIEW MORE
      </Button>
    </>
  );
};
