import React, { CSSProperties, useEffect, useState } from "react";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ObjectType from "@/components/Boards/Object";
import EventType from "@/components/Boards/Event";
import TextType from "@/components/Boards/Text";
import LinkType from "@/components/Boards/Link";
import ArtistType from "@/components/Boards/Artist";
import BusinessType from "@/components/Boards/Business";
import FileType from "@/components/Boards/File";
import VideoType from "@/components/Boards/Video";
import WritingType from "@/components/Boards/Writing";
import {
  BoardItemType,
  FileItem,
  GetSingleBoardResponse,
  VideoItem,
} from "@/types/boards.type";
import useBoard from "@/apis/board";
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { CreateBoardParams, ItemType } from "@/hooks/useFilter";
import toast from "react-hot-toast";
import CarouselDrawer from "./CaourselDrawer";

type BoardItem = BoardItemType;

interface BoardItemsPageProps {
  boardItems: BoardItem[];
  boardId: string;
  searchQuery: string;
  selectedItemType: string;
}

const BoardItemsPage: React.FC<BoardItemsPageProps> = ({
  boardItems,
  boardId,
  searchQuery,
  selectedItemType,
}) => {
  const [items, setItems] = useState(boardItems);
  const [videoItems, setVideoItems] = useState<VideoItem[]>([]);
  const [fileItems, setFileItems] = useState<FileItem[]>([]);
  const [activeItemIndex, setActiveItemIndex] = useState<string | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [rearangeLoading, setRearrangeLoading] = useState(false);
  const { editSingleBoard } = useBoard();

  useEffect(() => {
    setItems(boardItems);
  }, [boardItems]);

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 10 } }),
    useSensor(MouseSensor, { activationConstraint: { distance: 10 } }),
    useSensor(TouchSensor)
  );

  const queryClient = useQueryClient();

  const handleIndexChange = (newItems: BoardItem[]) => {
    const params: CreateBoardParams = {
      boardItems: newItems,
    };

    rearrangeMutation.mutate({
      boardId,
      params,
    });
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over === null || active.id === over.id) return;

    const oldIndex = items.findIndex((item) => item._id === active.id);
    const newIndex = items.findIndex((item) => item._id === over.id);

    if (oldIndex === -1 || newIndex === -1) return;

    const newItems = arrayMove(items, oldIndex, newIndex);
    setItems(newItems);
    setRearrangeLoading(true);
    handleIndexChange(newItems);
  };

  useEffect(() => {
    const filteredVideos = items.filter(
      (item): item is VideoItem => item.itemType === "video"
    );
    const filteredFiles = items.filter(
      (item): item is FileItem => item.itemType === "file"
    );
    setVideoItems(filteredVideos);
    setFileItems(filteredFiles);
  }, [items]);

  const filteredItems = items.filter((item) => {
    const query = searchQuery.toLowerCase();
    const matchesSearchQuery = (() => {
      switch (item.itemType) {
        case "object":
          return item.objectName?.toLowerCase().includes(query);
        case "text":
          return item.title?.toLowerCase().includes(query);
        case "events":
          return item.eventName?.toLowerCase().includes(query);
        case "link":
          return item.link?.toLowerCase().includes(query);
        case "artist":
          return item.name?.toLowerCase().includes(query);
        case "business":
          return item.businessName?.toLowerCase().includes(query);
        case "video":
          return item.content?.title?.toLowerCase().includes(query);
        case "writing":
          return item.content?.title?.toLowerCase().includes(query);
        case "file":
          return item.name?.toLowerCase().includes(query);
        default:
          return false;
      }
    })();

    const matchesItemType = selectedItemType
      ? item.itemType === selectedItemType
      : true;

    return matchesSearchQuery && matchesItemType;
  });

  const rearrangeMutation: UseMutationResult<
    GetSingleBoardResponse,
    Error,
    { boardId: string; params: CreateBoardParams }
  > = useMutation({
    mutationFn: ({ boardId, params }) => editSingleBoard({ boardId, params }), // Pass both boardId and params
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["single-board"],
      });
      setRearrangeLoading(false);
      toast.success("Rearranged successfully!");
    },
    onError: (error: Error) => {
      toast.error("Failed to rearrange. Please try again.");
    },
  });

  const handleItemClick = (itemId: string, itemType: ItemType) => {
    if (itemType === "video" || itemType === "file") {
      const initialIndex =
        itemType === "video"
          ? videoItems.findIndex((item) => item._id === itemId)
          : fileItems.findIndex((item) => item._id === itemId);

      setActiveItemIndex(itemId);
      setIsDialogOpen(true);
    } else {
      // Handle other item types or do nothing
    }
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      {rearangeLoading && (
        <div className="loader-overlay">
          <div className="loader">
            <span className="spinner" />
          </div>
        </div>
      )}
      <CarouselDrawer
        isOpen={isDialogOpen}
        onClose={closeDialog}
        videoItems={videoItems}
        fileItems={fileItems}
        initialId={activeItemIndex ?? ""}
      />
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={filteredItems.map((item) => item._id)}
          strategy={verticalListSortingStrategy}
        >
          <div className="masonry-layout py-5 min-h-[59vh]">
            {filteredItems.map((item, index) => (
              <SortableItem
                key={item._id}
                item={item}
                boardId={boardId}
                onItemClick={() => {
                  if (item.itemType === "video" || item.itemType === "file") {
                    handleItemClick(item._id, item.itemType);
                  }
                }}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
    </>
  );
};

interface SortableItemProps {
  item: BoardItem;
  boardId: string;
  onItemClick: () => void;
}

const SortableItem: React.FC<SortableItemProps> = ({
  item,
  boardId,
  onItemClick,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: item._id });

  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    cursor: isDragging ? "grabbing" : "grab",
    position: "relative",
    width: isDragging ? "initial" : undefined,
    height: isDragging ? "initial" : undefined,
  };

  const commonProps = {
    ref: setNodeRef,
    style,
    ...attributes,
    ...listeners,
    className: "masonry-item",
    onClick: onItemClick,
  };

  switch (item.itemType) {
    case "object":
      return (
        <div {...commonProps}>
          <ObjectType
            imgSrc={item.image}
            objectName={item.objectName}
            publishedYear={item.publishedYear ?? 0}
            asignProtect={item.assignProtect}
            boardId={boardId}
            coverId={item._id}
            className="flex-shrink-0"
            itemId={item._id}
            caption={item.caption ?? ""}
            showEllipsis
            itemType={item.itemType}
            tagsList={item.tags}
          />
        </div>
      );
    case "text":
      return (
        <div {...commonProps}>
          <TextType
            title={item.title}
            summary={item.summary}
            className="flex-shrink-0"
            boardId={boardId}
            itemId={item._id}
            itemType={item.itemType}
            caption={item.caption}
            tagList={item.tags}
          />
        </div>
      );
    case "events":
      return (
        <div {...commonProps}>
          <EventType
            eventName={item.eventName}
            image={item.image}
            description={item.description}
            state={item.state}
            country={item.country}
            startDate={item.startDate}
            endDate={item.endDate}
            itemId={item._id}
            boardId={boardId}
            itemType={item.itemType}
            caption={item.caption}
            className={""}
            coverId={item._id}
            tagList={item.tags}
          />
        </div>
      );
    case "link":
      return (
        <div {...commonProps}>
          <LinkType
            hyperLink={item.link}
            className=""
            boardId={boardId}
            itemId={item._id}
            caption={item.caption}
            tagList={item.tags}
          />
        </div>
      );
    case "artist":
      return (
        <div {...commonProps}>
          <ArtistType
            coverId={item._id}
            name={item.name}
            img={item.profilePic}
            dob={item.dob}
            className=""
            boardId={boardId}
            itemId={item._id}
            itemType={item.itemType}
            caption={item.caption}
            tagList={item.tags}
          />
        </div>
      );
    case "business":
      return (
        <div {...commonProps}>
          <BusinessType
            coverId={item._id}
            businessName={item.businessName}
            image={item.coverImage}
            description={item.description}
            businessDate={item.businessDate}
            businessLocation={item.businessLocation}
            businessLogo={item.image}
            itemId={item._id}
            itemType={item.itemType}
            caption={item.caption}
            boardId={boardId}
            tagList={item.tags}
          />
        </div>
      );
    case "video":
      return (
        <div {...commonProps}>
          <VideoType
            coverId={item._id}
            videoUrl={item.content.url}
            coverphoto={item.content.coverImage}
            title={item.content.title}
            className=""
            itemId={item._id}
            caption={item.caption ? item.caption : ""}
            boardId={boardId}
            tagList={item.tags}
            showEllipsis={true}
            showCaption={true}
          />
        </div>
      );
    case "writing":
      return (
        <div {...commonProps}>
          <WritingType
            title={item.content.title}
            image={item.content.coverImage}
            description={item.content.description}
            authorName={item.content.authorName}
            className={""}
            itemId={item._id}
            boardId={boardId}
            itemType={item.itemType}
            caption={item.caption ? item.caption : ""}
            coverId={item._id}
            imgSrc={item.content.coverImage}
            tagList={item.tags}
          />
        </div>
      );
    case "file":
      return (
        <div {...commonProps}>
          <FileType
            image={item.image}
            className=""
            fileName={item.name}
            itemId={item._id}
            boardId={boardId}
            itemType={item.itemType}
            caption={item.caption}
            coverId={item._id}
            imgSrc={item.image}
            tagList={item.tags}
          />
        </div>
      );
    default:
      return null;
  }
};

export default BoardItemsPage;
