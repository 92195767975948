import React from "react";

const TextFooter = () => {
  return (
    <footer className="mq450:hidden mq750:hidden flex items-center justify-center font-sh5 text-sm  text-gray-60 font-normal">
      Terms & Conditions | Privacy Policy | Contact us | © 2024 Democrart
      Technologies Private Limited
    </footer>
  );
};

export default TextFooter;
