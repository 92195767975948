import { cn } from "@/lib/utils";
import React, { useEffect, useRef, useState } from "react";

interface SidebarProps {
  data: {
    label: string;
  }[];
  onClick?: (label: string) => void;
  active: string;
}

export const MobileHeader = ({ data, onClick, active }: SidebarProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [atStart, setAtStart] = useState(true);
  const [atEnd, setAtEnd] = useState(false);
  const handleArrowClick = (direction: "left" | "right") => {
    if (containerRef.current) {
      const scrollAmount = direction === "left" ? -100 : 100; // Adjust this value to scroll more or less
      containerRef.current.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };
  const checkScrollPosition = () => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
      setAtStart(scrollLeft === 0);
      setAtEnd(scrollLeft + clientWidth >= scrollWidth);
    }
  };

  useEffect(() => {
    checkScrollPosition();
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", checkScrollPosition);
      return () => container.removeEventListener("scroll", checkScrollPosition);
    }
  }, []);
  return (
    <div className="hidden bg-[#F2F2F2] border-[#E5E5E5] border-solid border-[1px] mq450:block w-full relative">
      {!atStart && (
        <div className="absolute left-0 grid h-full bg-gradient-to-r from-[#F6F6F5] items-center min-w-[80px] z-10 top-0">
          <img
            src="/arrow-right.svg"
            alt="Scroll Left"
            className="rotate-180 cursor-pointer"
            onClick={() => handleArrowClick("left")}
          />
        </div>
      )}
      <div
        ref={containerRef}
        className="flex flex-row items-center gap-6 px-5 pt-2 overflow-x-scroll font-sh5 text-other-cta"
      >
        {data.map((item) => (
          <p
            key={item.label}
            onClick={() => {
              if (typeof onClick == "function") onClick(item.label as string);
            }}
            className={cn(
              "pb-4 border-b-[1.5px] border-solid cursor-pointer border-transparent text-gray-60 whitespace-nowrap text-sm",
              active === item.label && "border-[#303030] text-gray-100"
            )}
          >
            {item.label}
          </p>
        ))}
        {!atEnd && (
          <div className="absolute right-0 grid h-full bg-gradient-to-l from-[#F6F6F5]  min-w-[80px] z-10 items-center justify-end">
            <img
              src="/arrow-right.svg"
              alt="Scroll Right"
              className="mb-1 cursor-pointe"
              onClick={() => handleArrowClick("right")}
            />
          </div>
        )}
      </div>
    </div>
  );
};
