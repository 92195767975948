import React from "react";

interface itemCaptionprop {
  caption: string;
}
const CaptionItem: React.FC<itemCaptionprop> = ({ caption }) => {
  return (
    <div>
      {" "}
      <div className="bg-white border-solid border-[1px] border-gray-20 px-4 py-3">
        <div className="flex flex-row items-center justify-start gap-2">
          <img src="/description.svg" alt="desc icon" />
          <p className="text-black font-sh5 text-sm font-normal">{caption}</p>
        </div>
      </div>
    </div>
  );
};

export default CaptionItem;
