import React, { ReactNode } from "react";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { Drawer, DrawerContent, DrawerHeader } from "@/components/ui/drawer";
import "react-toastify/dist/ReactToastify.css";
import { cn } from "@/lib/utils";
import { useMediaQuery } from "@mui/material";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import EditBoard from "@/modal/EditBoard";
import DeleteBoard from "@/modal/DeleteBoard";
import RemoveCover from "@/modal/RemoveCover";

type EditDropDownProps = {
  children: ReactNode;
  boardId: string;
  boardName: string;
  isCoverIDEmpty: string;
  descBoard: string;
  nameBoard: string;
  icon: number;
};

const EditDropDown: React.FC<EditDropDownProps> = ({
  children,
  boardId,
  boardName,
  isCoverIDEmpty,
  descBoard,
  nameBoard,
  icon,
}) => {
  const isDesktop = useMediaQuery("(min-width: 768px)");

  return isDesktop ? (
    <DropdownMenu>
      <DropdownMenuTrigger className="bg-transparent">
        {children}
      </DropdownMenuTrigger>
      <DropdownMenuContent className="p-8 border border-solid rounded-none border-gainsboro">
        <DropdownMenuGroup className="flex flex-col gap-[10px] text-base text-gray-60 font-sh5">
          <ProfileForm
            boardId={boardId}
            boardName={boardName}
            isCoverIDEmpty={isCoverIDEmpty}
            descBoard={descBoard}
            nameBoard={nameBoard}
            icon={icon}
          />
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  ) : (
    <Drawer>
      <DialogTrigger className="text-lg text-white bg-transparent">
        {children}
      </DialogTrigger>
      <DrawerContent className="p-8 rounded-none">
        <DrawerHeader className="p-0 "></DrawerHeader>
        <ProfileForm
          boardId={boardId}
          boardName={boardName}
          isCoverIDEmpty={isCoverIDEmpty}
          descBoard={descBoard}
          nameBoard={nameBoard}
          icon={icon}
        />
      </DrawerContent>
    </Drawer>
  );
};

type ProfileFormProps = {
  className?: string;
  boardId: string;
  boardName: string;
  descBoard: string;
  nameBoard: string;
  isCoverIDEmpty: string;
  icon: number;
};

function ProfileForm({
  className,
  boardId,
  boardName,
  descBoard,
  nameBoard,
  isCoverIDEmpty,
  icon,
}: ProfileFormProps) {
  return (
    <form className={cn("grid items-center gap-4 ", className)}>
      <RemoveCover boardId={boardId}>
        <div className="text-base font-normal text-left cursor-pointer text-gray-60 mq450:text-left font-sh5">
          Remove Cover
        </div>
      </RemoveCover>
      {/* )} */}
      <EditBoard
        boardId={boardId}
        boardName={boardName}
        nameBoard={nameBoard}
        descBoard={descBoard}
        icon={icon}
      >
        <div className="text-base font-normal text-left cursor-pointer text-gray-60 mq450:text-left font-sh5">
          Edit Board Details
        </div>
      </EditBoard>
      <DeleteBoard boardId={boardId} boardName={boardName}>
        <div className="text-base font-normal text-left cursor-pointer text-gray-60 mq450:text-left font-sh5">
          Delete Board
        </div>
      </DeleteBoard>
    </form>
  );
}

export default EditDropDown;
