import useUserAPI, { like } from "@/apis/user";
import { Button } from "@/components/ui/button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import toast from "react-hot-toast";

interface props extends like {
  children: React.ReactNode;
  itemId: string;
  itemType: "artist" | "object" | "event" | "business";
  action: "add" | "remove";
}

const successToastMessage = (
  itemType: "artist" | "object" | "event" | "business",
  action: "add" | "remove"
) => {
  const messages = {
    artist: {
      add: "Followed successfully",
      remove: "Unfollowed successfully",
    },
    business: {
      add: "Followed successfully",
      remove: "Unfollowed successfully",
    },
    object: {
      add: "Liked successfully",
      remove: "Unliked successfully",
    },
    event: {
      add: "Liked successfully",
      remove: "Unliked successfully",
    },
  };

  return messages[itemType][action];
};

const Like = (props: props) => {
  const { addOrRemoveLike } = useUserAPI();
  const queryclient = useQueryClient();
  const handler = useMutation({
    mutationFn: addOrRemoveLike,
    mutationKey: ["like"],
    onSuccess: () => {
      toast.dismiss();
      queryclient.invalidateQueries({ queryKey: ["user-interactions"] });
      toast.success(successToastMessage(props.itemType, props.action));
    },
    onError: (error: any) => {
      toast.dismiss();
      toast.error(error.message);
    },
  });

  const buttonClick = () => {
    handler.mutate({
      itemId: props.itemId,
      itemType: props.itemType,
      action: props.action,
    });
  };

  return <div onClick={buttonClick}>{props.children}</div>;
};

export default Like;
