import React from "react";
import Header from "../components/Global/header/Header";
import Sidebar from "../components/Global/Sidebar";

const Layout: React.FC<{
  children: React.ReactNode;
  viewMobTopNav?: boolean;
}> = ({ children, viewMobTopNav }) => {
  return (
    <div className=" w-full max-h-screen overflow-hidden relative bg-white flex flex-col items-start justify-start leading-[normal] tracking-[normal] container">
      <Header />
      <main className="flex flex-row items-start self-stretch justify-start max-w-full mq750:p-0 mq450:flex-col mq450:p-0 mq450:w-full mq450:relative mq975:box-border">
        <Sidebar viewMobTopNav={viewMobTopNav} />
        <section className="mq450:relative flex-1 mq750:max-h-[calc(100vh-150px)] mq450:max-h-[calc(100vh-200px)] max-h-[calc(100vh-80px)] overflow-y-scroll flex flex-col  items-start justify-start  box-border  max-w-[calc(100%_-_252px)] text-left text-13xl text-grey100 mq975:max-w-full mq725:gap-[15px] mq450:w-full">
          {children}
        </section>
      </main>
    </div>
  );
};

export default Layout;
