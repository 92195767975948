import React, { ReactNode, useState } from "react";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerClose,
} from "@/components/ui/drawer";
import "react-toastify/dist/ReactToastify.css";
import { cn } from "@/lib/utils";
import { useMediaQuery } from "@mui/material";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useBoard from "@/apis/board";
import toast from "react-hot-toast";

type AddTextProps = {
  children: ReactNode;
  boardId: string;
};

const AddText: React.FC<AddTextProps> = ({ children, boardId }) => {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [isOpen, setIsOpen] = useState(false); // State for managing modal/drawer visibility
  const queryClient = useQueryClient();

  const handleClose = () => setIsOpen(false); // Handler to close the modal/drawer

  return isDesktop ? (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger
        className="text-lg text-white bg-transparent"
        onClick={() => setIsOpen(true)}
      >
        {children}
      </DialogTrigger>
      <DialogContent className="max-w-[754px]">
        <DialogHeader>
          <DialogTitle className="px-[32px] border-[#E5E5E5] pb-5 border-b-[1px] border-solid text-gray-100 font-normal font-sh5 text-[28px]">
            <div className="flex flex-row justify-between">
              <p>Add Text</p>
              <div className="flex flex-row items-center gap-4">
                <img
                  className="cursor-pointer"
                  src="/delete.svg"
                  onClick={() => {
                    setTitle("");
                    setText("");
                  }}
                />
                <DialogClose asChild>
                  <img
                    className="cursor-pointer"
                    src="/close1.svg"
                    alt="Close"
                  />
                </DialogClose>
              </div>
            </div>
          </DialogTitle>
        </DialogHeader>
        <ProfileForm
          title={title}
          setTitle={setTitle}
          text={text}
          setText={setText}
          boardId={boardId}
          onClose={handleClose} // Pass the handler to ProfileForm
        />
      </DialogContent>
    </Dialog>
  ) : (
    <Drawer open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger
        className="text-lg text-white bg-transparent"
        onClick={() => setIsOpen(true)}
      >
        {children}
      </DialogTrigger>
      <DrawerContent className="rounded-none">
        <DrawerHeader>
          <DrawerTitle className="border-[#E5E5E5] pb-5 border-b-[1px] border-solid text-gray-100 font-normal font-sh5 text-[28px]">
            <div className="flex flex-row justify-between">
              <p>Add Text</p>
              <div className="flex flex-row items-center gap-4">
                <img src="/delete.svg" />
                <DrawerClose asChild>
                  <img src="/close1.svg" alt="Close" />
                </DrawerClose>
              </div>
            </div>
          </DrawerTitle>
        </DrawerHeader>
        <ProfileForm
          title={title}
          setTitle={setTitle}
          text={text}
          setText={setText}
          boardId={boardId}
          onClose={handleClose}
        />
      </DrawerContent>
    </Drawer>
  );
};

type ProfileFormProps = {
  className?: string;
  boardId: string;
  title: string;
  setTitle: (title: string) => void;
  text: string;
  setText: (text: string) => void;
  onClose: () => void; // Add onClose prop
};

function ProfileForm({
  className,
  boardId,
  title,
  setTitle,
  text,
  setText,
  onClose, // Receive onClose function
}: ProfileFormProps) {
  const queryClient = useQueryClient();
  const { addItem } = useBoard();
  const mutation = useMutation({
    mutationFn: addItem,
    onSuccess: () => {
      toast.success("Item added successfully!", {
        id: "add-item",
      });
      setTitle(""); // Clear fields on success
      setText("");
      onClose(); // Close dialog or drawer on success
      queryClient.invalidateQueries({
        queryKey: ["single-board"],
      });
    },
    onError: (error) => {
      toast.error(`Error adding item: ${error.message}`, {
        id: "add-item",
      });
    },
    onMutate: () => {
      toast.loading("Adding item...", {
        id: "add-item",
      });
    },
  });

  const handleSave = () => {
    if (title.trim() === "" || text.trim() === "") {
      toast.error("Title and text are required.");
      return;
    }

    mutation.mutate({
      boardId,
      itemType: "text",
      title,
      summary: text,
    });
  };

  return (
    <form className={cn("grid items-center gap-4 ", className)}>
      <div className="flex flex-col px-5 ">
        <textarea
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-full rounded-none border-none focus:border-none focus:outline-none font-sh5 font-medium placeholder:text-[#979797] text-[36px] resize-none"
        />
        <textarea
          onChange={(e) => setText(e.target.value)}
          value={text}
          placeholder="Text"
          className="w-full text-black text-sm font-sh5 min-h-[40vh] md:min-h-[50vh] border-none focus:border-none focus:outline-none  resize-none"
        />
      </div>

      <div className="flex mt-2 flex-row justify-between w-full px-5 py-3 bg-[#F2F2F2]">
        <DialogClose asChild>
          <button
            className="relative text-sm font-bold text-gray-100 underline bg-transparent rounded-none cursor-pointer"
            type="button"
            onClick={onClose}
          >
            CANCEL
          </button>
        </DialogClose>
        <Button
          className="rounded-[50px] min-w-[130px] px-4 font-bold text-sm text-white"
          variant="default"
          type="button"
          onClick={handleSave}
        >
          SAVE
        </Button>
      </div>
    </form>
  );
}

export default AddText;
