import React from "react";
import { Button } from "../ui/button";
import LinkDropDown from "./LinkDropDown";
import CaptionItem from "./CaptionItem";

interface LinkProp {
  hyperLink: string;
  className?: string;
  boardId: string;
  itemId: string;
  tagList: string[];
  caption?: string;
  showEllipsis?: boolean;
  showCaption?: boolean;
}

const LinkType: React.FC<LinkProp> = ({
  hyperLink,
  className,
  boardId,
  itemId,
  caption,
  tagList,
  showEllipsis = true,
  showCaption = true,
}) => {
  return (
    <div className="flex flex-col">
      <div
        className={`bg-bg-5 px-[21px] w-full  py-6 h-fit flex relative items-center ${className}`}
      >
        <Button variant={"outline"}>
          <a href={hyperLink} className="text-blue-500 underline">
            {hyperLink}
          </a>
        </Button>
        {showEllipsis && (
          <LinkDropDown
            boardId={boardId}
            itemId={itemId}
            coverId={""}
            imgSrc={""}
            objectName={""}
            price={0}
            assignProtect={false}
            caption={caption}
            tagList={tagList}
          >
            <img
              className="absolute top-0 right-0 p-2 cursor-pointer"
              alt="options"
              src="/ellipsis.svg"
            />
          </LinkDropDown>
        )}
      </div>
      {showCaption && (caption ? <CaptionItem caption={caption} /> : null)}
    </div>
  );
};

export default LinkType;
