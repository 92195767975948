import React, { useState, useEffect, useRef } from "react";
import Keycloak from "keycloak-js";
import Cookies from "universal-cookie";
const client = new Keycloak({
  url: "https://keycloak-production-928d.up.railway.app",
  realm: "asign",
  clientId: "asign_client",
});

const useAuth = () => {
  const cookies = new Cookies();
  const isRun = useRef(false);
  const [token, setToken] = useState<string | null>(null);
  const [isLogin, setLogin] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (isRun.current) return;

    isRun.current = true;

    client
      .init({
        onLoad: "login-required",
        checkLoginIframe: false,
      })
      .then((authenticated) => {
        console.log("Authenticated:", authenticated);
        setLogin(authenticated);
        if (authenticated) {
          setToken(client.token ?? null);
          cookies.set("token", client.token as string);

          client.onTokenExpired = () => {
            console.log("Token expired, attempting to refresh...");
            client
              .updateToken(30)
              .then((refreshed) => {
                if (refreshed) {
                  cookies.set("token", client.token as string);
                  setToken(client.token ?? null);
                }
              })
              .catch((err) => {
                console.error("Failed to refresh the token:", err);
                setLogin(false);
                setError("Failed to refresh the token.");
              });
          };
        }
      })
      .catch((err) => {
        console.error("Authentication failed:", err);
        setError(`Authentication failed: ${err.message}`);
        setLogin(false);
      });
  }, []);

  return { isLogin, token, error };
};

export default useAuth;
