import React, { ReactNode } from "react";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { Drawer, DrawerContent, DrawerHeader } from "@/components/ui/drawer";
import "react-toastify/dist/ReactToastify.css";
import { cn } from "@/lib/utils";
import { useMediaQuery } from "@mui/material";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import useBoard from "@/apis/board";
import toast from "react-hot-toast";
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { GetSingleBoardResponse } from "@/types/boards.type";
import { BoardItemType, CreateBoardParams } from "@/hooks/useFilter";
import RemoveItem from "@/modal/RemoveItem";
import AddCaption from "@/modal/AddCaption";
import AddTag from "@/modal/AddTag";
import RemoveCaption from "@/modal/RemoveCaption";
import ShareModal from "../ShareModal";
const { editSingleBoard } = useBoard();

interface ObjectDropDownProp {
  children: ReactNode;
  boardId: string;
  coverId: string;
  itemId: string;
  imgSrc: string;
  objectName: string;
  publishedYear: number;
  assignProtect: boolean;
  caption?: string;
  itemType: string;
  tagsList: string[];
}

const ObjecDropDown: React.FC<ObjectDropDownProp> = ({
  children,
  boardId,
  itemId,
  imgSrc,
  objectName,
  publishedYear,
  assignProtect,
  coverId,
  caption,
  itemType,
  tagsList,
}) => {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const queryClient = useQueryClient();

  const coverMutation: UseMutationResult<
    GetSingleBoardResponse,
    Error,
    { boardId: string; params: CreateBoardParams }
  > = useMutation({
    mutationFn: ({ boardId, params }) => editSingleBoard({ boardId, params }), // Pass both boardId and params
    onSuccess: () => {
      toast.success("Cover updated successfully!");
      queryClient.invalidateQueries({ queryKey: ["single-board"] });
    },
    onError: (error: Error) => {
      toast.error("Failed to update cover. Please try again.");
    },
  });

  const handleCover = () => {
    coverMutation.mutate({
      boardId,
      params: { coverId },
    });
  };

  return isDesktop ? (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
      <DropdownMenuContent className="border-[1px]  border-bdr-10 border-solid p-2 min-w-[200px]  rounded-none">
        <DropdownMenuGroup className="flex flex-col gap-2 text-base text-gray-60 font-sh5">
          <ProfileForm
            setCover={handleCover}
            boardId={boardId}
            itemId={itemId}
            imgSrc={imgSrc}
            objectName={objectName}
            publishedYear={publishedYear}
            assignProtect={assignProtect}
            coverId={coverId}
            caption={caption}
            itemType={itemType}
            tagsList={tagsList}
          />
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  ) : (
    <Drawer>
      <DialogTrigger className="text-lg text-white bg-transparent" asChild>
        {children}
      </DialogTrigger>
      <DrawerContent className="rounded-none">
        <DrawerHeader className="p-0"></DrawerHeader>
        <ProfileForm
          setCover={handleCover}
          boardId={boardId}
          itemId={itemId}
          imgSrc={imgSrc}
          objectName={objectName}
          publishedYear={publishedYear}
          assignProtect={assignProtect}
          coverId={coverId}
          caption={caption}
          itemType={itemType}
          tagsList={tagsList}
        />
      </DrawerContent>
    </Drawer>
  );
};

type ProfileFormProps = {
  className?: string;
  setCover: () => void;
  boardId: string;
  itemId: string;
  imgSrc: string;
  objectName: string;
  publishedYear: number;
  assignProtect: boolean;
  coverId: string;
  itemType: string;
  caption?: string;
  tagsList: string[];
};

function ProfileForm({
  className,
  setCover,
  boardId,
  itemId,
  imgSrc,
  caption,
  itemType,
  tagsList,
}: ProfileFormProps) {
  return (
    <form
      className={cn(
        "grid items-center gap-4 mq450:gap-8 mq450:p-6 p-5",
        className,
      )}
    >
      {caption ? (
        <RemoveCaption boardId={boardId} itemId={itemId}>
          <div className="text-base font-normal text-center cursor-pointer text-gray-60 mq450:text-left font-sh5">
            Remove Caption
          </div>
        </RemoveCaption>
      ) : (
        <AddCaption boardId={boardId} itemId={itemId} captionImg={imgSrc}>
          <div className="text-base font-normal text-center cursor-pointer text-gray-60 mq450:text-left font-sh5">
            Add Caption
          </div>
        </AddCaption>
      )}
      <AddTag
        boardId={boardId}
        itemId={itemId}
        itemType={itemType}
        tagList={tagsList}
      >
        {" "}
        <div className="text-base font-normal text-center cursor-pointer text-gray-60 mq450:text-left font-sh5">
          Add Tags
        </div>
      </AddTag>
      <ShareModal url={`asign-discover.pages.dev/${itemType}/detail/${itemId}`}>
        <div className="text-base font-normal text-center cursor-pointer text-gray-60 mq450:text-left font-sh5">
          Share
        </div>
      </ShareModal>
      <div
        onClick={setCover}
        className="text-base font-normal text-center cursor-pointer text-gray-60 mq450:text-left font-sh5"
      >
        Make Cover
      </div>
      <RemoveItem boardId={boardId} itemId={itemId}>
        <div className="text-base font-normal text-center cursor-pointer text-gray-60 mq450:text-left font-sh5">
          Remove from Board
        </div>{" "}
      </RemoveItem>
    </form>
  );
}

export default ObjecDropDown;
