import { Paginator, QueryData } from "@/hooks/useFilter";
import { apis } from ".";
import { User } from "@/types/user.types";
export interface like {
  itemId: string;
  itemType: "artist" | "object" | "event" | "business";
  action: "add" | "remove";
}

export interface UserInteraction {
  artistsFollowed: string[];
  objectsLiked: string[];
  eventsBooked: string[];
  businessFollowed: string[];
  addedBy: string;
  createdAt: string;
  updatedAt: string;
  isDeleted: boolean;
  isActive: boolean;
  recentArtistsViewed: string[];
  recentObjectsViewed: string[];
  recentbusinessViewed: string[];
  recenteventsViewed: string[];
  surveyResponses: SurveyResponse[];
  id: string;
}

export interface SurveyResponse {
  sectionId?: string;
  response?: {
    ans?: string;
  };
  itemType: "artist" | "object" | "business" | "event";
}
const useUserAPI = () => {
  const searchUsers = async (
    searchParams: QueryData
  ): Promise<{
    data: User[];
    paginator: Paginator;
  }> => {
    try {
      const { data: response } = await apis.post(
        "/cms-users/api/v1/user/list",
        {
          data: searchParams,
        }
      );

      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const userById = async ({ id }: { id: string }): Promise<User> => {
    try {
      const { data } = await apis.get(`/cms-users/api/v1/user/${id}`, {});
      return data.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const addOrRemoveLike = async (boady: like) => {
    try {
      await apis.post("/client/api/v1/userinteractions/create", {
        data: boady,
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getUserIntreaction = async (): Promise<UserInteraction> => {
    try {
      const { data } = await apis.get(
        "/client/api/v1/userinteractions/interactionsbyuser",
        {}
      );
      return data.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return { searchUsers, userById, addOrRemoveLike, getUserIntreaction };
};

export default useUserAPI;
