import React, { ReactNode } from "react";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { Drawer, DrawerContent, DrawerHeader } from "@/components/ui/drawer";
import "react-toastify/dist/ReactToastify.css";
import { cn } from "@/lib/utils";
import { useMediaQuery } from "@mui/material";
import useBoard from "@/apis/board";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import RemoveItem from "@/modal/RemoveItem";
import AddTag from "@/modal/AddTag";
import RemoveCaption from "@/modal/RemoveCaption";
import AddCaptionLink from "@/modal/AddCaptionLink";
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { CreateBoardParams } from "@/hooks/useFilter";
import { GetSingleBoardResponse } from "@/types/boards.type";
import toast from "react-hot-toast";

const { editSingleBoard } = useBoard();
interface TextDropDownProp {
  children: ReactNode;
  boardId: string;
  itemId: string;
  caption?: string;
  itemType: string;
  coverId: string;
  tagList: string[];
}

const TextDropDown: React.FC<TextDropDownProp> = ({
  children,
  boardId,
  itemId,
  caption,
  itemType,
  coverId,
  tagList,
}) => {
  const isDesktop = useMediaQuery("(min-width: 768px)");

  const handleDropdownClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const coverMutation: UseMutationResult<
    GetSingleBoardResponse,
    Error,
    { boardId: string; params: CreateBoardParams }
  > = useMutation({
    mutationFn: ({ boardId, params }) => editSingleBoard({ boardId, params }), // Pass both boardId and params
    onSuccess: () => {
      toast.success("Cover updated successfully!");
      queryClient.invalidateQueries({ queryKey: ["single-board"] });
    },
    onError: (error: Error) => {
      toast.error("Failed to update cover. Please try again.");
    },
  });
  const queryClient = useQueryClient();

  const handleCover = () => {
    coverMutation.mutate({
      boardId,
      params: { coverId },
    });
  };
  return isDesktop ? (
    <DropdownMenu>
      <DropdownMenuTrigger className="cursor-pointer" asChild>
        {children}
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={handleDropdownClick}
        className="border-[1px] border-bdr-10 border-solid p-2 min-w-[200px] rounded-none"
      >
        <DropdownMenuGroup className="gap-2 text-base text-gray-60 font-sh5 flex flex-col">
          <ProfileForm
            setCover={handleCover}
            itemType={itemType}
            boardId={boardId}
            itemId={itemId}
            caption={caption}
            tagList={tagList}
          />
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  ) : (
    <Drawer>
      <DialogTrigger
        onClick={handleDropdownClick}
        className="text-lg bg-transparent text-white"
      >
        {children}
      </DialogTrigger>
      <DrawerContent className="rounded-none">
        <DrawerHeader className="p-0"></DrawerHeader>
        <ProfileForm
          setCover={handleCover}
          itemType={itemType}
          boardId={boardId}
          itemId={itemId}
          caption={caption}
          tagList={tagList}
        />
      </DrawerContent>
    </Drawer>
  );
};

type ProfileFormProps = {
  className?: string;
  boardId: string;
  itemId: string;
  caption?: string;
  tagList: string[];
  itemType: string;
  setCover: () => void;
};

function ProfileForm({
  className,
  boardId,
  itemId,
  caption,
  itemType,
  setCover,
  tagList,
}: ProfileFormProps) {
  return (
    <form className={cn("grid items-center gap-6 p-8", className)}>
      {caption ? (
        <RemoveCaption boardId={boardId} itemId={itemId}>
          <div className="cursor-pointer font-normal text-gray-60 text-base  text-left font-sh5">
            Remove Caption
          </div>
        </RemoveCaption>
      ) : (
        <AddCaptionLink boardId={boardId} itemId={itemId}>
          <div className="cursor-pointer font-normal text-gray-60 text-base  text-left font-sh5">
            Add Caption
          </div>
        </AddCaptionLink>
      )}
      <AddTag
        boardId={boardId}
        itemId={itemId}
        itemType={itemType}
        tagList={tagList}
      >
        <div className="cursor-pointer font-normal text-gray-60 text-base  text-left  font-sh5">
          Add Tags
        </div>
      </AddTag>

      <div
        onClick={setCover}
        className="cursor-pointer font-normal text-gray-60 text-base text-left  font-sh5"
      >
        Make Cover
      </div>
      <RemoveItem boardId={boardId} itemId={itemId}>
        <div className="cursor-pointer font-normal text-gray-60 text-base text-left  font-sh5">
          Remove from Board
        </div>{" "}
      </RemoveItem>
    </form>
  );
}

export default TextDropDown;
