import Header from "@/components/Global/header/Header";
import React, { useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
} from "@/components/ui/breadcrumb";
import { Switch } from "@/components/ui/switch";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Separator } from "@/components/ui/separator";
import Footer from "@/components/Global/Footer";
import WorkCard from "@/components/cards/WorkCard";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useArtist, { sectionParams } from "@/apis/artist";
import { useQuery } from "@tanstack/react-query";
import Loader from "@/components/Global/Loader";
import { format } from "date-fns";
import { useDebouncedCallback } from "use-debounce";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Checkbox } from "@/components/ui/checkbox";
import { sectionType } from "@/types/global.type";
import useGlobalApis from "@/apis/global";
import Image from "@/components/Global/Image";
import { formatInRuppes } from "@/lib/helper";
import PageLoader from "@/components/loader/PageLoader";
import { FollowArtistButton } from "@/components/Templates/Artists/BigBanner";

export const ArtistView = ({ item }: any) => {
  const navigate = useNavigate();

  return (
    <div key={item._id}>
      <Separator className="my-[42px] invisible lg:visible" />
      <div className="flex items-center justify-between mb-[32px] w-full">
        <div className="flex items-center gap-[20px] w-full mq700:justify-between">
          <div className="flex items-center gap-[10px]">
            <Avatar
              onClick={() => navigate(`/artist/detail/${item._id}`)}
              className="cursor-pointer"
            >
              <AvatarImage src={item.profilePic} alt="@shadcn" />
              <AvatarFallback>{item.name[0]}</AvatarFallback>
            </Avatar>
            <div>
              <p className="w-full text-[16px] font-normal capitalize truncate">
                {item.name}
              </p>
              <p className="text-sm text-gray-60">
                b. {format(item?.dob ?? new Date(), "yyyy")}
              </p>
            </div>
          </div>
          <FollowArtistButton
            id={item._id}
            className="rounded-[33px]  w-[100px]  bg-gray-100  "
          />
        </div>
        <div className="flex-row items-center hidden lg:flex">
          <Button
            className="text-sm underline bg-transparent"
            variant="link"
            size="sm"
            onClick={() => navigate(`/artist/detail/${item._id}`)}
          >
            VIEW ALL
          </Button>
        </div>
      </div>

      <div className="flex flex-wrap items-center justify-center md:justify-between gap-y-10">
        {item.gallery.map((item: any) => (
          <WorkCard
            key={item._id}
            image={item.art_url}
            description={item.art_desc}
            label={item.art_name}
          />
        ))}
      </div>
    </div>
  );
};

export const ObjectView = ({ item }: any) => {
  const navigate = useNavigate();

  return (
    <div className="">
      <div className="relative overflow-hidden aspect-square w-[320px] ">
        {!item?.image ? (
          <div className="w-full h-full bg-gradient-to-b from-gray-200 to-black" />
        ) : (
          <Image
            src={item.image}
            alt="profile pic"
            className="object-cover w-full h-full brightness-[50%] "
            loading="lazy"
          />
        )}
      </div>
      <div className="">
        {item.assignProtect && (
          <p className="flex items-center mt-2 text-xs truncate font-sh5">
            <img src="/protect.svg" alt="asign protect" />
            Asign Protect+
          </p>
        )}
        <p className="text-sm">{item?.artistName}</p>
        <p className="text-sm text-gray-60">{item?.objectName}</p>
        <p className="text-sm text-gray-60">b. date</p>
        <span className="text-sm">
          {formatInRuppes.format(item?.price as number)}
        </span>
      </div>
    </div>
  );
};

export const EventView = ({ item }: any) => {
  return (
    <div key={item.id} className="">
      <Image
        src={item?.image}
        alt={item?.eventName}
        className="w-[340px] h-[245px] object-cover object-center "
      />
      <div className="grid gap-1">
        <h4 className="font-normal capitalize">{item.eventName}</h4>
        <p className="text-sm capitalize text-gray-60">{item.country}</p>
        <p className="text-sm text-gray-60">
          {format(new Date(), "MMM d, yyyy")} -{" "}
          {format(new Date(), "MMM d, yyyy")}{" "}
        </p>
        <p className="text-sm text-gray-60">
          {" "}
          {format(new Date(), "h:mm a")} - {format(new Date(), "h:mm a")}
        </p>
      </div>
    </div>
  );
};

export const BussinessView = ({ item }: any) => {
  const navigate = useNavigate();

  return (
    <div className="p-5 border border-solid border-gray-20">
      {/* logo and title   */}
      <div className="flex items-center gap-5">
        <Image
          src="https://picsum.photos/300/200"
          alt=""
          className="object-cover object-center w-10 h-10 rounded-full"
        />
        <div className="flex justify-between w-full">
          <p className="leading-5">
            <span className="capitalize truncate">{item.businessName} </span>
            <br />
            <span className="text-sm capitalize text-gray-60">
              {" "}
              {item.businessLocation}
            </span>
          </p>

          <Button variant="outline" className="rounded-full">
            Follow
          </Button>
        </div>
      </div>
      {/* /image */}
      <Image
        src={item.image}
        alt={item.businessName}
        className="mt-4 h-full w-[300px] max-h-[200px] object-cover object-center cursor-pointer"
        onClick={() => navigate(`/business/detail/${item._id}`)}
      />
    </div>
  );
};
const View = () => {
  const { id, type } = useParams() as { id: string; type: sectionType };

  const { getSectionById, getFilters } = useGlobalApis();

  const [filterOption, setFilterOption] = useState<sectionParams>({});

  const search = useDebouncedCallback((value: string) => {
    setFilterOption({
      ...filterOption,
      searchQuery: value,
    });
  }, 700);

  const setQuery = (query: object) => {
    setFilterOption((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        ...query,
      },
    }));
  };

  const { data: view, isLoading } = useQuery({
    queryKey: ["view", id, filterOption],
    queryFn: () => getSectionById({ id, params: filterOption, type }),
    enabled: Boolean(id),
  });

  const { data: filters } = useQuery({
    queryKey: ["filters", type],
    queryFn: () => getFilters(type),
    enabled: Boolean(type),
  });

  return (
    <>
      <Header />
      <div className="container font-normal font-sh5 lg:px-[48px] px-2">
        <Breadcrumb className="lg:mt-[64px] mt-5">
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href="/">Discover</BreadcrumbLink>
            </BreadcrumbItem>
            <span className="text-black">/</span>
            <BreadcrumbItem>
              <BreadcrumbLink
                href={`
                /${type}/feed
              `}
              >
                {type}
              </BreadcrumbLink>
            </BreadcrumbItem>
            <span className="text-black">/</span>
            <BreadcrumbItem>
              <BreadcrumbPage>{view?.title}</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>

        <div className="sticky z-10 pb-1 mb-5 bg-white top-1">
          <h4 className="my-10 text-3xl font-normal capitalize">
            {view?.title}
          </h4>
          <div className="flex items-center gap-4">
            <p className="items-center hidden gap-2 text-sm lg:flex">
              Objects for sale <Switch />
            </p>
            <div className="flex items-center justify-between flex-1 gap-2">
              <div className="hidden gap-2 lg:flex">
                {filters?.map((item: any) => (
                  <Select
                    key={item.name}
                    onValueChange={(value) => setQuery({ [item.name]: value })}
                    defaultValue={filterOption?.filters?.[item.name]}
                  >
                    <SelectTrigger className="w-[116px] rounded-full">
                      <SelectValue
                        placeholder={item.label}
                        className="capitalize placeholder:capitalize"
                      />
                    </SelectTrigger>
                    <SelectContent>
                      {item.options.map((item: any) => (
                        <SelectItem
                          value={item.value}
                          key={item.value}
                          className="capitalize"
                        >
                          {item.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                ))}
              </div>

              <div className="flex items-center w-full gap-1 px-2 border border-solid border-gray-20 max-w-[368px] rounded-full">
                <img src="/search.svg" alt="search" />
                <input
                  className="w-full font-normal bg-transparent border-none outline-none h-9 font-sh5"
                  placeholder="Search"
                  type="text"
                  defaultValue={filterOption?.searchQuery ?? ""}
                  onChange={(e) => search(e.target.value)}
                />
              </div>

              <Drawer>
                <DrawerTrigger asChild>
                  <div className="grid p-1 border border-solid rounded-full h-9 w-9 border-gray-20 place-items-center lg:hidden">
                    <img src="/filter.svg" alt="" />
                  </div>
                </DrawerTrigger>
                <DrawerContent>
                  <DrawerHeader>
                    <DrawerTitle className="font-normal text-left text-gray-60 font-sh5">
                      Filters
                    </DrawerTitle>
                    <Separator className="mt-1" />
                  </DrawerHeader>
                  <Accordion type="single" collapsible className="w-full px-5">
                    <AccordionItem value="item-1">
                      <AccordionTrigger>Sale Stats</AccordionTrigger>
                      <AccordionContent>
                        <div className="grid gap-y-4">
                          {[1, 2, 3].map((item) => (
                            <p className="flex items-center gap-2" key={item}>
                              <Checkbox id={item.toString()} />
                              <label htmlFor={item.toString()}>
                                Panting (56)
                              </label>
                            </p>
                          ))}
                        </div>
                      </AccordionContent>
                    </AccordionItem>
                    <AccordionItem value="item-2">
                      <AccordionTrigger>Price</AccordionTrigger>
                      <AccordionContent>
                        <div className="grid gap-y-4">
                          {[1, 2, 3].map((item) => (
                            <p className="flex items-center gap-2" key={item}>
                              <Checkbox id={item.toString()} />
                              <label htmlFor={item.toString()}>
                                Panting (56)
                              </label>
                            </p>
                          ))}
                        </div>
                      </AccordionContent>
                    </AccordionItem>
                    <AccordionItem value="item-3">
                      <AccordionTrigger>Medium</AccordionTrigger>
                      <AccordionContent>
                        <div className="grid gap-y-4">
                          {[1, 2, 3].map((item) => (
                            <p className="flex items-center gap-2" key={item}>
                              <Checkbox id={item.toString()} />
                              <label htmlFor={item.toString()}>
                                Panting (56)
                              </label>
                            </p>
                          ))}
                        </div>
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                </DrawerContent>
              </Drawer>
            </div>
          </div>
        </div>

        {type === "artist" &&
          view?.sectionDetails.map((item) => (
            <ArtistView key={item._id} item={item} />
          ))}

        {type === "objects" && (
          <div className="flex flex-wrap gap-5 ">
            {view?.sectionDetails.map((item) => (
              <ObjectView key={item._id} item={item} />
            ))}
          </div>
        )}
        {type === "events" && (
          <div className="flex flex-wrap gap-5">
            {view?.sectionDetails.map((item) => (
              <EventView key={item._id} item={item} />
            ))}
          </div>
        )}
        {type === "business" && (
          <div className="flex flex-wrap gap-5">
            {view?.sectionDetails.map((item) => (
              <BussinessView key={item._id} item={item} />
            ))}
          </div>
        )}
        {isLoading && <PageLoader />}
        {(!view || (view?.sectionDetails.length === 0 && !isLoading)) && (
          <div className="grid place-items-center h-[500px] bg-[#FCFCFC] my-5">
            <div className="flex flex-col items-center gap-1 font-normal font-sh5">
              <img src="/database.svg" alt="" />
              <p>No Data Found</p>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default View;
