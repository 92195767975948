import Image from "@/components/Global/Image";
import Like from "@/components/Global/intreactions/Like";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { useUserContext } from "@/context/user";
import { findById } from "@/lib/helper";
import { cn } from "@/lib/utils";
import { Artist } from "@/types/atrists.type";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

interface Props {
  data: Artist[];
  title: string;
  sectionId?: string;
}
const CustomDot = () => (
  <div className="w-[8px] h-[8px] rounded-full bg-gray-80"></div>
);

const BigBanner = ({ data, title, sectionId }: Props) => {
  const sliderRef = useRef<Slider | null>(null);

  const next = () => {
    sliderRef.current?.slickNext();
  };

  const previous = () => {
    sliderRef.current?.slickPrev();
  };
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const navigate = useNavigate();

  const settings = {
    dots: true,
    customPaging: CustomDot,
    dotsClass: "slick-dots dots-inside-the-slider",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const updateWidth = () => setContainerWidth(container.offsetWidth);
    const resizeObserver = new ResizeObserver(updateWidth);
    resizeObserver.observe(container);
    updateWidth();

    return () => resizeObserver.disconnect();
  }, []);

  const isSmallWidth = containerWidth < 375;

  const renderSlide = (item: Artist | number, index: number) => {
    const isPlaceholder = typeof item === "number";
    const artist = isPlaceholder ? null : item;

    return (
      <div className="relative group" key={isPlaceholder ? index : artist?._id}>
        <div
          className={cn(
            "relative overflow-hidden",
            isSmallWidth ? "aspect-[9/12]" : "aspect-video",
            isPlaceholder || !artist?.profilePic
              ? "bg-gradient-to-b from-gray-200 to-black"
              : ""
          )}
        >
          {!isPlaceholder && artist?.profilePic && (
            <img
              src={artist?.image ?? artist.profilePic}
              alt="profile pic"
              className="object-cover w-full h-full brightness-[50%] "
              loading="lazy"
            />
          )}
        </div>

        {!isSmallWidth && (
          <>
            <Badge className="absolute capitalize top-5 left-5 lg:p-[12px_16px]">
              {artist?.tags}
            </Badge>

            {[false, true].map((flip, index) => (
              <img
                key={index}
                className={cn(
                  "p-[3px] bg-[#EEEEEA] rounded-[100%] h-[20px] w-[20px] absolute top-1/2  cursor-pointer group-hover:visible invisible z-10",
                  flip && " left-2 rotate-180 ",
                  !flip && "right-2 top-[51%] "
                )}
                src="/arrow-right.svg"
                alt={flip ? "Previous" : "Next"}
                onClick={flip ? previous : next}
              />
            ))}
            <div className="absolute w-full text-white top-1/2 -translate-y-1/2 flex flex-col items-center justify-center gap-[5px]">
              <h1
                className="text-[48px] font-thin tracking-wide capitalize cursor-pointer font-eb"
                onClick={() => navigate(`/artist/detail/${artist?._id}`)}
              >
                {isPlaceholder ? "Artist Name" : artist?.name}
              </h1>
              <p className="text-sm font-sh5">
                {artist?.description ?? "Abstract artist based out of New York"}
              </p>
              <FollowArtistButton
                id={artist?._id ?? ""}
                className="p-[10px_20px]"
              />
            </div>
            <Button
              className="mt-1 bg-white rounded-[33px] absolute bottom-10 left-1/2 -translate-x-1/2 w-full max-w-[200px] font-sh5 font-medium p-[9px_24px]"
              variant="outline"
              onClick={() => navigate(`/artist/view/${sectionId}`)}
            >
              VIEW ALL ARTIST{isPlaceholder ? "S" : ""}
            </Button>
          </>
        )}

        {isSmallWidth && (
          <div className="absolute left-0 flex items-center justify-between w-full px-2 text-white bottom-10">
            <div>
              <h1 className="text-xl font-normal tracking-wide font-eb">
                {isPlaceholder ? "name" : artist?.name}
              </h1>
              <p className="text-[10px] font-sh5">
                b .{format(new Date(artist?.dob ?? new Date()), "yyyy")}
              </p>
            </div>
            <Button
              variant="outline"
              size="sm"
              className="text-white border-white rounded-[33px]"
            >
              Follow
            </Button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="" ref={containerRef}>
      <Slider {...settings} ref={sliderRef}>
        {data?.length <= 1
          ? [1, 2, 3, 4, 5].map((item, index) => renderSlide(item, index))
          : data?.map((item, index) => renderSlide(item, index))}
      </Slider>
      {isSmallWidth && (
        <Button
          className="w-full  mt-1 rounded-[33px] bg-other-cta uppercase font-medium font-sh5 p-[9px_24px] text-base"
          onClick={() => navigate(`/artist/view/${sectionId}`)}
        >
          View All Artists
        </Button>
      )}
    </div>
  );
};

export default BigBanner;

export const FollowArtistButton = ({
  id,
  className,
}: {
  id: string;
  className?: string;
}) => {
  const { interactions } = useUserContext();
  return (
    <Like
      itemId={id ?? " "}
      action={
        findById(interactions?.artistsFollowed, id ?? "") ? "remove" : "add"
      }
      itemType="artist"
    >
      <Button
        variant="outline"
        size="sm"
        className={cn(
          "text-white border-white rounded-[33px] p-[8px_20px]",
          className
        )}
      >
        {findById(interactions?.artistsFollowed, id ?? "")
          ? "Unfollow"
          : "Follow"}
      </Button>
    </Like>
  );
};
