import React, { useRef, useState } from "react";
import { BoardItemType } from "@/types/boards.type";
import { Button } from "@/components/ui/button";
import AddItemDropDown from "../AddItemDropDown";

interface AllProps {
  items: BoardItemType[];
}
const formatDate = (dateString: string | number | Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options);
};
const getYearFromDateString = (dateString: string): number => {
  const date = new Date(dateString);
  return date.getFullYear();
};

const All: React.FC<AllProps> = ({ items }) => {
  const [playingVideoId, setPlayingVideoId] = useState<string | null>(null);
  const videoRefs = useRef<Map<string, HTMLVideoElement>>(new Map());

  const handlePlay = (videoId: string) => {
    if (playingVideoId === videoId) {
      // If the clicked video is already playing, pause it
      setPlayingVideoId(null);
    } else {
      // Pause the currently playing video (if any)
      if (playingVideoId) {
        videoRefs.current.get(playingVideoId)?.pause();
      }
      // Play the clicked video
      setPlayingVideoId(videoId);
    }
  };
  return (
    <div>
      {items.length === 0 ? (
        <p>No items found.</p>
      ) : (
        items.map((item) => {
          const isPlaying = playingVideoId === item._id;
          switch (item.itemType) {
            case "object":
              return (
                <div
                  key={item._id}
                  className="group flex  w-full min-w-[297px]  h-fit  flex-col relative items-center justify-center bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
                >
                  <div className="flex flex-col items-center flex-1 w-full">
                    <img
                      src={item.image}
                      alt={item.objectName}
                      className="object-cover w-full h-full"
                    />
                    <div className="absolute inset-0 invisible rounded-t-lg group-hover:visible bg-gradient-to-t from-zinc-900 to-transparent"></div>
                    <AddItemDropDown
                      itemID={item._id}
                      boardID={""}
                      itemType={item.itemType}
                    >
                      <span className="absolute right-0 invisible p-2 m-2 bg-white rounded-full cursor-pointer group-hover:visible text-other-cta">
                        <div className="flex flex-row items-center gap-1 ">
                          <img
                            className="w-6 h-6"
                            src="/label.svg"
                            alt="label"
                          />
                        </div>
                      </span>
                    </AddItemDropDown>
                    <div className="absolute bottom-0 left-0 flex flex-col w-full gap-1 p-4">
                      <div className="flex flex-row items-center gap-[2px]">
                        <img
                          src="/icon-protect-1.svg"
                          alt="Protect Icon"
                          className="w-5 h-5"
                        />
                        {item.assignProtect && (
                          <p className="text-xs font-normal text-white font-sh5">
                            Asign Protect+
                          </p>
                        )}
                      </div>
                      <h2 className="ml-1 text-sm font-normal text-white font-sh5">
                        {item.objectName}
                      </h2>
                      {item.publishedYear && (
                        <p className="font-sh5 ml-1 font-normal text-sm text-[#CCCCCC]">
                          c. {item.publishedYear}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              );
            case "artist":
              return (
                <div
                  key={item._id}
                  className={`group min-w-[301px] w-full h-full min-h-[300px] relative `}
                >
                  <img
                    className="object-cover w-full h-full"
                    src={item.profilePic}
                  />

                  <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-gray-100 to-70% opacity-0 group-hover:opacity-100 transition-opacity ease-in-out duration-300 cursor-pointer" />
                  <AddItemDropDown
                    itemID={item._id}
                    boardID={""}
                    itemType={item.itemType}
                  >
                    <span className="absolute right-0 invisible p-2 m-2 bg-white rounded-full cursor-pointer group-hover:visible text-other-cta">
                      <div className="flex flex-row items-center gap-1">
                        <img className="w-6 h-6" src="/label.svg" alt="label" />
                      </div>
                    </span>
                  </AddItemDropDown>
                  <div className="absolute bottom-0 left-0 p-4">
                    <p className="text-5xl font-normal text-white font-eb">
                      {item.name}
                    </p>
                    <p className="text-[#CCCCCC] font-sh5 font-normal text-xs">
                      b. {getYearFromDateString(item.dob)}
                    </p>
                  </div>
                  <div className="absolute bottom-0 right-0 p-4">
                    <Button
                      size="icon"
                      variant="outline"
                      className="w-[104px] text-white rounded-full font-sh5 font-normal text-sm"
                    >
                      FOLLOW
                    </Button>
                  </div>
                </div>
              );
            case "business":
              return (
                <div
                  key={item._id}
                  className={`group flex min-h-[297px] min-w-[297px] w-full h-full flex-col relative items-center justify-center bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 `}
                >
                  <div className="flex flex-col items-center flex-1 w-full">
                    <img
                      src={item.coverImage}
                      className="object-fill w-full bg-gradient-to-t from-zinc-900 to-neutral-50"
                    />
                    <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-gray-100 to-70% opacity-0 group-hover:opacity-100 transition-opacity ease-in-out duration-300 cursor-pointer" />
                    <AddItemDropDown
                      itemID={item._id}
                      boardID={""}
                      itemType={item.itemType}
                    >
                      <span className="absolute right-0 invisible p-2 m-2 bg-white rounded-full cursor-pointer group-hover:visible text-other-cta">
                        <div className="flex flex-row items-center gap-1">
                          <img
                            className="w-6 h-6"
                            src="/label.svg"
                            alt="label"
                          />
                        </div>
                      </span>
                    </AddItemDropDown>
                    <div className="absolute bottom-0 left-0 flex flex-row w-full gap-2 p-4 item-center">
                      <img
                        className="w-14 h-14 rounded-[100px]"
                        src={item.image}
                      />
                      <div className="flex flex-col justify-center gap-2">
                        <h2 className="text-sm font-normal text-white font-sh5">
                          {item.businessName}
                        </h2>
                        <h2 className="font-sh5 font-normal text-sm text-[#CCCCCC]">
                          {item.businessLocation}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              );
            case "events":
              return (
                <div
                  key={item._id}
                  className={`  group h-[297px] w-full min-w-[297px]  relative flex flex-col justify-end bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 `}
                >
                  <img
                    className="object-cover w-full h-full"
                    src={item.image}
                  />
                  <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-gray-100 to-70% opacity-0 group-hover:opacity-100 transition-opacity ease-in-out duration-300 cursor-pointer" />
                  <AddItemDropDown
                    itemID={item._id}
                    boardID={""}
                    itemType={item.itemType}
                  >
                    <div className="">
                      <span className="absolute top-0 right-0 flex flex-row items-center invisible gap-1 p-2 m-2 bg-white rounded-full cursor-pointer group-hover:visible text-other-cta">
                        <img className="w-6 h-6" src="/label.svg" alt="label" />
                      </span>
                    </div>
                  </AddItemDropDown>
                  <div>
                    {" "}
                    <div className="absolute bottom-0 left-0 p-4">
                      <p className="text-xs font-normal text-white fobt-sh5">
                        {item.eventName}
                      </p>
                      <p className="text-[#CCCCCC] font-sh5 font-normal text-xs">
                        {item.country}
                      </p>
                      <p className="text-[#CCCCCC] font-sh5 font-normal text-xs">
                        {formatDate(item.startDate)} -{" "}
                        {formatDate(item.endDate)}
                      </p>
                    </div>
                    <div className="absolute bottom-0 right-0 p-4">
                      <Button
                        size="icon"
                        variant="outline"
                        className="w-[104px] text-white  rounded-full font-sh5 font-normal text-sm"
                      >
                        BUY TICKETS
                      </Button>
                    </div>
                  </div>
                </div>
              );
            case "video":
              return (
                <div
                  key={item._id}
                  className="group h-[297px] w-full min-w-[297px]  relative flex flex-col justify-end bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
                >
                  <p className="absolute p-1 text-white bg-black bg-opacity-50 rounded bottom-2 left-2">
                    {item.content.title}
                  </p>
                  <div
                    className={`absolute inset-0 flex justify-center items-center cursor-pointer ${
                      isPlaying ? "hidden" : "block"
                    }`}
                    onClick={() => handlePlay(item._id)}
                  >
                    <img
                      src={item.content.coverImage}
                      alt={item.content.title}
                      className="object-cover w-full h-full"
                    />

                    <button
                      className="absolute p-3 transition-opacity bg-white rounded-full opacity-75 hover:opacity-100"
                      aria-label="Play"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-10 h-10 text-gray-800"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M8 5v14l11-7z" />
                      </svg>
                    </button>
                  </div>
                  <video
                    ref={(el) => videoRefs.current.set(item._id, el!)}
                    src={item.content.url}
                    controls
                    className={`absolute inset-0 w-full h-full ${
                      isPlaying ? "block" : "hidden"
                    }`}
                    onClick={() => handlePlay(item._id)}
                  >
                    Your browser does not support the video tag.
                  </video>
                  <AddItemDropDown
                    itemID={item._id}
                    boardID={""}
                    itemType={item.itemType}
                  >
                    <span className="absolute top-0 right-0 invisible p-2 m-2 bg-white rounded-full cursor-pointer group-hover:visible text-other-cta">
                      <div className="flex flex-row items-center gap-1">
                        <img className="w-6 h-6" src="/label.svg" alt="label" />
                      </div>
                    </span>
                  </AddItemDropDown>
                </div>
              );
            case "writing":
              return (
                <div
                  key={item._id}
                  className={`group h-[297px] w-full min-w-[297px]  relative flex flex-col justify-end bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 `}
                >
                  <img
                    className="object-cover w-full h-full"
                    src={item.content.coverImage}
                  />
                  <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-gray-100 to-70% opacity-0 group-hover:opacity-100 transition-opacity ease-in-out duration-300 cursor-pointer" />
                  <AddItemDropDown
                    itemID={item._id}
                    boardID={""}
                    itemType={item.itemType}
                  >
                    <span className="absolute top-0 right-0 invisible p-2 m-2 bg-white rounded-full cursor-pointer group-hover:visible text-other-cta">
                      <div className="flex flex-row items-center gap-1">
                        <img className="w-6 h-6" src="/label.svg" alt="label" />
                      </div>
                    </span>
                  </AddItemDropDown>
                  <div>
                    {" "}
                    <div className="absolute bottom-0 left-0 p-4">
                      <p className="text-xs font-normal text-white fobt-sh5">
                        {item.content.authorName}
                      </p>
                      {/* <p className="text-[#CCCCCC] font-sh5 font-normal text-xs">
                        {item.content.description}
                      </p> */}
                    </div>
                  </div>
                </div>
              );
            default:
              return null; // Return null for undefined or unexpected item types.
          }
        })
      )}
    </div>
  );
};

export default All;
