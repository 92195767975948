import Layout from "../layout/DefaultLayout";
import React from "react";

const Profile = () => {
  return (
    <div>
      <Layout viewMobTopNav={true}>
        <p>profile</p>
      </Layout>
    </div>
  );
};

export default Profile;
