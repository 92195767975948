import React, { lazy, Suspense } from "react";
import Layout from "../layout/DefaultLayout";
import { useQuery } from "@tanstack/react-query";
import useArtist from "@/apis/artist";
import useFilter from "@/hooks/useFilter";
import LazyLoader from "@/components/LazyLoader";
const Preview = lazy(() => import("@/components/Preview/Preview"));

const Artists = () => {
  const { filterOption } = useFilter({
    query: {
      sectionStatus: "publish",
    },
  });
  const { getFeed } = useArtist();
  const feed = useQuery({
    queryKey: ["artist-feed", filterOption],
    queryFn: () => getFeed(filterOption),
  });

  return (
    <Layout viewMobTopNav={true}>
      <Suspense fallback={<LazyLoader />}>
        <Preview data={feed.data?.data} type="artist" />
      </Suspense>
    </Layout>
  );
};

export default Artists;
